import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
};

export type Brand = {
  __typename?: 'Brand';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type BrandAttributes = {
  name?: InputMaybe<Scalars['String']>;
};

export type BrandFilter = {
  OR?: InputMaybe<Array<BrandFilter>>;
  name?: InputMaybe<Scalars['String']>;
};

export type ChoiceGroup = {
  __typename?: 'ChoiceGroup';
  choiceGroupItems?: Maybe<Array<ChoiceGroupItem>>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  vendor?: Maybe<Vendor>;
  vendorId: Scalars['Int'];
};

export type ChoiceGroupAttributes = {
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

export type ChoiceGroupFilter = {
  OR?: InputMaybe<Array<ChoiceGroupFilter>>;
  name?: InputMaybe<Scalars['String']>;
};

export type ChoiceGroupItem = {
  __typename?: 'ChoiceGroupItem';
  choiceGroup?: Maybe<ChoiceGroup>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ChoiceGroupItemAttributes = {
  choiceGroupId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type ChoiceGroupItemFilter = {
  OR?: InputMaybe<Array<ChoiceGroupItemFilter>>;
  name?: InputMaybe<Scalars['String']>;
};

export type ClientFilter = {
  OR?: InputMaybe<Array<ClientFilter>>;
  name?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateBrand */
export type CreateBrandInput = {
  attributes?: InputMaybe<BrandAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateChoiceGroup */
export type CreateChoiceGroupInput = {
  attributes?: InputMaybe<ChoiceGroupAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateChoiceGroupItem */
export type CreateChoiceGroupItemInput = {
  attributes?: InputMaybe<ChoiceGroupItemAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateCustomer */
export type CreateCustomerInput = {
  attributes?: InputMaybe<CustomerAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateDeal */
export type CreateDealInput = {
  attributes?: InputMaybe<DealAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateDiscount */
export type CreateDiscountInput = {
  attributes?: InputMaybe<DiscountAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateExpense */
export type CreateExpenseInput = {
  attributes?: InputMaybe<ExpenseAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateExpenseItem */
export type CreateExpenseItemInput = {
  attributes?: InputMaybe<ExpenseItemAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateFoodAvailability */
export type CreateFoodAvailabilityInput = {
  attributes?: InputMaybe<FoodAvailabilityAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateFoodCategory */
export type CreateFoodCategoryInput = {
  attributes: FoodCategoryAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateFoodItem */
export type CreateFoodItemInput = {
  attributes?: InputMaybe<FoodItemAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateFoodItemVariant */
export type CreateFoodItemVariantInput = {
  attributes?: InputMaybe<FoodItemVariantAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateMenu */
export type CreateMenuInput = {
  attributes?: InputMaybe<MenuAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateOrder */
export type CreateOrderInput = {
  attributes?: InputMaybe<OrderAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateOrderItem */
export type CreateOrderItemInput = {
  attributes?: InputMaybe<OrderItemAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateProductCategory */
export type CreateProductCategoryInput = {
  attributes?: InputMaybe<ProductCategoryAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateProduct */
export type CreateProductInput = {
  attributes?: InputMaybe<ProductAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreatePurchase */
export type CreatePurchaseInput = {
  attributes?: InputMaybe<PurchaseAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreatePurchaseItem */
export type CreatePurchaseItemInput = {
  attributes?: InputMaybe<PurchaseItemAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreatePurchaseLineItem */
export type CreatePurchaseLineItemInput = {
  attributes?: InputMaybe<PurchaseLineItemAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateStock */
export type CreateStockInput = {
  attributes?: InputMaybe<StockAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateStockItem */
export type CreateStockItemInput = {
  attributes?: InputMaybe<StockItemAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateSupplier */
export type CreateSupplierInput = {
  attributes?: InputMaybe<SupplierAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateUserLocation */
export type CreateUserLocationInput = {
  attributes?: InputMaybe<UserLocationAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateVendorCustomer */
export type CreateVendorCustomerInput = {
  attributes?: InputMaybe<VendorCustomerAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CreateVendor */
export type CreateVendorInput = {
  attributes?: InputMaybe<VendorAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

export type Customer = {
  __typename?: 'Customer';
  billingStatus?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  customerNumber: Scalars['Int'];
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
  userId: Scalars['Int'];
  userLocations?: Maybe<Array<UserLocation>>;
  vendor: Vendor;
  vendorId: Scalars['Int'];
};

export type CustomerAttributes = {
  billingStatus?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  salutation?: InputMaybe<Scalars['String']>;
};

export type CustomerFilter = {
  OR?: InputMaybe<Array<CustomerFilter>>;
  customerNumber?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['String']>;
};

export type DailySale = {
  __typename?: 'DailySale';
  collectionAmount?: Maybe<Scalars['Float']>;
  createdAt: Scalars['ISO8601DateTime'];
  date?: Maybe<Scalars['ISO8601Date']>;
  expenseAmount?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  netProfit?: Maybe<Scalars['Float']>;
  profit?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Deal = {
  __typename?: 'Deal';
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  dealItems?: Maybe<Array<DealItem>>;
  discountedPriceAmount: Scalars['Float'];
  discountedPriceCurrency: Scalars['String'];
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  serving?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  status?: Maybe<Scalars['String']>;
  totalPriceAmount: Scalars['Float'];
  totalPriceCurrency: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  vendor?: Maybe<Vendor>;
  vendorId: Scalars['Int'];
};

export type DealAttributes = {
  code?: InputMaybe<Scalars['String']>;
  dealItems?: InputMaybe<Array<DealItemAttributes>>;
  discountedPriceAmount?: InputMaybe<Scalars['Float']>;
  endDate?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  serving?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  totalPriceAmount?: InputMaybe<Scalars['Float']>;
  vendorId: Scalars['Int'];
};

export type DealFilter = {
  OR?: InputMaybe<Array<DealFilter>>;
  name?: InputMaybe<Scalars['String']>;
};

export type DealItem = {
  __typename?: 'DealItem';
  createdAt: Scalars['ISO8601DateTime'];
  dealId: Scalars['Int'];
  discountedPriceAmount: Scalars['Float'];
  discountedPriceCurrency: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  priceAmount: Scalars['Float'];
  priceCurrency: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type DealItemAttributes = {
  discountedPriceAmount: Scalars['Float'];
  name: Scalars['String'];
  priceAmount: Scalars['Float'];
  quantity: Scalars['Int'];
};

/** Autogenerated input type of DeleteBrand */
export type DeleteBrandInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DeleteChoiceGroup */
export type DeleteChoiceGroupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DeleteChoiceGroupItem */
export type DeleteChoiceGroupItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DeleteCustomer */
export type DeleteCustomerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DeleteDeal */
export type DeleteDealInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DeleteDiscount */
export type DeleteDiscountInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

/** Autogenerated input type of DeleteExpense */
export type DeleteExpenseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DeleteExpenseItem */
export type DeleteExpenseItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DeleteFoodAvailability */
export type DeleteFoodAvailabilityInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DeleteFoodCategory */
export type DeleteFoodCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DeleteFoodItem */
export type DeleteFoodItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DeleteFoodItemVariant */
export type DeleteFoodItemVariantInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DeleteMenu */
export type DeleteMenuInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DeleteOrder */
export type DeleteOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DeleteOrderItem */
export type DeleteOrderItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DeleteProductCategory */
export type DeleteProductCategoryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DeleteProduct */
export type DeleteProductInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DeletePurchase */
export type DeletePurchaseInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DeletePurchaseItem */
export type DeletePurchaseItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DeletePurchaseLineItem */
export type DeletePurchaseLineItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DeleteStock */
export type DeleteStockInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DeleteStockItem */
export type DeleteStockItemInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DeleteSupplier */
export type DeleteSupplierInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DeleteUserLocation */
export type DeleteUserLocationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DeleteVendorCustomer */
export type DeleteVendorCustomerInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of DeleteVendor */
export type DeleteVendorInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type Discount = {
  __typename?: 'Discount';
  applicableOn?: Maybe<Scalars['String']>;
  audience?: Maybe<Scalars['String']>;
  closingTime?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  daysOfWeek?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  discountType?: Maybe<Scalars['String']>;
  discountValue?: Maybe<Scalars['Float']>;
  expiryDate?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['ID'];
  maxDiscountAmount?: Maybe<Scalars['Float']>;
  minOrderValue?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  openingTime?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['ISO8601Date']>;
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  uuid: Scalars['String'];
  vendorId: Scalars['Int'];
};

export type DiscountAttributes = {
  applicableOn?: InputMaybe<Scalars['String']>;
  audience?: InputMaybe<Scalars['String']>;
  closingTime?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  daysOfWeek?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  discountType?: InputMaybe<Scalars['String']>;
  discountValue?: InputMaybe<Scalars['Float']>;
  expiryDate?: InputMaybe<Scalars['ISO8601Date']>;
  maxDiscountAmount?: InputMaybe<Scalars['Float']>;
  minOrderValue?: InputMaybe<Scalars['Float']>;
  name?: InputMaybe<Scalars['String']>;
  openingTime?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
  status?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

export type DiscountFilter = {
  OR?: InputMaybe<Array<DiscountFilter>>;
  name?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

export type Expense = {
  __typename?: 'Expense';
  cost?: Maybe<Scalars['String']>;
  costAmount: Scalars['Float'];
  costCurrency: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  expenseDate?: Maybe<Scalars['ISO8601DateTime']>;
  formattedExpenseDate?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  quantityWithUnit?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['String']>;
  rateAmount: Scalars['Float'];
  rateCurrency: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ExpenseAttributes = {
  costAmount?: InputMaybe<Scalars['Float']>;
  costCurrency?: InputMaybe<Scalars['String']>;
  expenseDate?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Float']>;
  rateAmount?: InputMaybe<Scalars['Float']>;
  rateCurrency?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
};

export type ExpenseFilter = {
  OR?: InputMaybe<Array<ExpenseFilter>>;
  endDate?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type ExpenseItem = {
  __typename?: 'ExpenseItem';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  latestRateAmount: Scalars['Float'];
  latestRateCurrency: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ExpenseItemAttributes = {
  name?: InputMaybe<Scalars['String']>;
};

export type ExpenseItemFilter = {
  OR?: InputMaybe<Array<ExpenseItemFilter>>;
  name?: InputMaybe<Scalars['String']>;
};

export type ExpensesSummary = {
  __typename?: 'ExpensesSummary';
  expenseItems: Array<ExpensesTotalItem>;
  totalExpenses: Scalars['Int'];
};

export type ExpensesTotalItem = {
  __typename?: 'ExpensesTotalItem';
  name: Scalars['String'];
  quantity: Scalars['Float'];
  totalCostAmount: Scalars['Float'];
};

export type FoodAvailability = {
  __typename?: 'FoodAvailability';
  createdAt: Scalars['ISO8601DateTime'];
  endTime: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  menu?: Maybe<Menu>;
  startTime: Scalars['ISO8601DateTime'];
  updatedAt: Scalars['ISO8601DateTime'];
  weekDays?: Maybe<Array<Scalars['String']>>;
};

export type FoodAvailabilityAttributes = {
  endTime?: InputMaybe<Scalars['String']>;
  menuId?: InputMaybe<Scalars['Int']>;
  startTime?: InputMaybe<Scalars['String']>;
  weekDays?: InputMaybe<Array<Scalars['String']>>;
};

export type FoodAvailabilityFilter = {
  OR?: InputMaybe<Array<FoodAvailabilityFilter>>;
  startTime?: InputMaybe<Scalars['String']>;
};

export type FoodCategory = {
  __typename?: 'FoodCategory';
  createdAt: Scalars['ISO8601DateTime'];
  foodItems?: Maybe<Array<FoodItem>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  vendor?: Maybe<Vendor>;
  vendorId: Scalars['Int'];
};

export type FoodCategoryAttributes = {
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

export type FoodCategoryFilter = {
  OR?: InputMaybe<Array<FoodCategoryFilter>>;
  menuId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};

export type FoodItem = {
  __typename?: 'FoodItem';
  choiceGroup?: Maybe<ChoiceGroup>;
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  foodCategory?: Maybe<FoodCategory>;
  foodCategoryId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  menu?: Maybe<Menu>;
  menuId?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  price?: Maybe<Scalars['String']>;
  priceAmount: Scalars['Float'];
  priceCurrency: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type FoodItemAttributes = {
  choiceGroupId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  foodCategoryId?: InputMaybe<Scalars['Int']>;
  menuId?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  priceAmount: Scalars['Float'];
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type FoodItemFilter = {
  OR?: InputMaybe<Array<FoodItemFilter>>;
  foodCategoryId?: InputMaybe<Scalars['ID']>;
  menuId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type FoodItemVariant = {
  __typename?: 'FoodItemVariant';
  choiceGroup?: Maybe<ChoiceGroup>;
  createdAt: Scalars['ISO8601DateTime'];
  foodItem?: Maybe<FoodItem>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type FoodItemVariantAttributes = {
  foodItemId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type FoodItemVariantFilter = {
  OR?: InputMaybe<Array<FoodItemVariantFilter>>;
  name?: InputMaybe<Scalars['String']>;
};

export type ItemOrder = {
  /** The attribute you want to order by. */
  attribute: Scalars['String'];
  /** Set a direction with "asc" or "desc". */
  direction: Scalars['String'];
};

export type Menu = {
  __typename?: 'Menu';
  createdAt: Scalars['ISO8601DateTime'];
  foodItems?: Maybe<Array<FoodItem>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  vendor?: Maybe<Vendor>;
  vendorId: Scalars['Int'];
};

export type MenuAttributes = {
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

export type MenuFilter = {
  OR?: InputMaybe<Array<MenuFilter>>;
  name?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['ID']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createBrand?: Maybe<Brand>;
  createChoiceGroup?: Maybe<ChoiceGroup>;
  createChoiceGroupItem?: Maybe<ChoiceGroupItem>;
  createCustomer?: Maybe<Customer>;
  createDeal?: Maybe<Deal>;
  createDiscount?: Maybe<Discount>;
  createExpense?: Maybe<Expense>;
  createExpenseItem?: Maybe<ExpenseItem>;
  createFoodAvailability?: Maybe<FoodAvailability>;
  createFoodCategory?: Maybe<FoodCategory>;
  createFoodItem?: Maybe<FoodItem>;
  createFoodItemVariant?: Maybe<FoodItemVariant>;
  createMenu?: Maybe<Menu>;
  createOrder?: Maybe<Order>;
  createOrderItem?: Maybe<OrderItem>;
  createProduct?: Maybe<Product>;
  createProductCategory?: Maybe<ProductCategory>;
  createPurchase?: Maybe<Purchase>;
  createPurchaseItem?: Maybe<PurchaseItem>;
  createPurchaseLineItem?: Maybe<PurchaseLineItem>;
  createStock?: Maybe<Stock>;
  createStockItem?: Maybe<StockItem>;
  createSupplier?: Maybe<Supplier>;
  createUserLocation?: Maybe<UserLocation>;
  createVendor?: Maybe<Vendor>;
  createVendorCustomer?: Maybe<VendorCustomer>;
  deleteBrand?: Maybe<Brand>;
  deleteChoiceGroup?: Maybe<ChoiceGroup>;
  deleteChoiceGroupItem?: Maybe<ChoiceGroupItem>;
  deleteCustomer?: Maybe<Customer>;
  deleteDeal?: Maybe<Deal>;
  deleteDiscount?: Maybe<Discount>;
  deleteExpense?: Maybe<Expense>;
  deleteExpenseItem?: Maybe<ExpenseItem>;
  deleteFoodAvailability?: Maybe<FoodAvailability>;
  deleteFoodCategory?: Maybe<FoodCategory>;
  deleteFoodItem?: Maybe<FoodItem>;
  deleteFoodItemVariant?: Maybe<FoodItemVariant>;
  deleteMenu?: Maybe<Menu>;
  deleteOrder?: Maybe<Order>;
  deleteOrderItem?: Maybe<OrderItem>;
  deleteProduct?: Maybe<Product>;
  deleteProductCategory?: Maybe<ProductCategory>;
  deletePurchase?: Maybe<Purchase>;
  deletePurchaseItem?: Maybe<PurchaseItem>;
  deletePurchaseLineItem?: Maybe<PurchaseLineItem>;
  deleteStock?: Maybe<Stock>;
  deleteStockItem?: Maybe<StockItem>;
  deleteSupplier?: Maybe<Supplier>;
  deleteUserLocation?: Maybe<UserLocation>;
  deleteVendor?: Maybe<Vendor>;
  deleteVendorCustomer?: Maybe<VendorCustomer>;
  updateBrand?: Maybe<Brand>;
  updateChoiceGroup?: Maybe<ChoiceGroup>;
  updateChoiceGroupItem?: Maybe<ChoiceGroupItem>;
  updateCustomer?: Maybe<Customer>;
  updateDeal?: Maybe<Deal>;
  updateDiscount?: Maybe<Discount>;
  updateExpense?: Maybe<Expense>;
  updateExpenseItem?: Maybe<ExpenseItem>;
  updateFoodAvailability?: Maybe<FoodAvailability>;
  updateFoodCategory?: Maybe<FoodCategory>;
  updateFoodItem?: Maybe<FoodItem>;
  updateFoodItemVariant?: Maybe<FoodItemVariant>;
  updateMenu?: Maybe<Menu>;
  updateOrder?: Maybe<Order>;
  updateOrderItem?: Maybe<OrderItem>;
  updateProduct?: Maybe<Product>;
  updateProductCategory?: Maybe<ProductCategory>;
  updatePurchase?: Maybe<Purchase>;
  updatePurchaseItem?: Maybe<PurchaseItem>;
  updatePurchaseLineItem?: Maybe<PurchaseLineItem>;
  updateStock?: Maybe<Stock>;
  updateStockItem?: Maybe<StockItem>;
  updateSupplier?: Maybe<Supplier>;
  updateUserLocation?: Maybe<UserLocation>;
  updateVendor?: Maybe<Vendor>;
  updateVendorCustomer?: Maybe<VendorCustomer>;
};


export type MutationCreateBrandArgs = {
  input: CreateBrandInput;
};


export type MutationCreateChoiceGroupArgs = {
  input: CreateChoiceGroupInput;
};


export type MutationCreateChoiceGroupItemArgs = {
  input: CreateChoiceGroupItemInput;
};


export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};


export type MutationCreateDealArgs = {
  input: CreateDealInput;
};


export type MutationCreateDiscountArgs = {
  input: CreateDiscountInput;
};


export type MutationCreateExpenseArgs = {
  input: CreateExpenseInput;
};


export type MutationCreateExpenseItemArgs = {
  input: CreateExpenseItemInput;
};


export type MutationCreateFoodAvailabilityArgs = {
  input: CreateFoodAvailabilityInput;
};


export type MutationCreateFoodCategoryArgs = {
  input: CreateFoodCategoryInput;
};


export type MutationCreateFoodItemArgs = {
  input: CreateFoodItemInput;
};


export type MutationCreateFoodItemVariantArgs = {
  input: CreateFoodItemVariantInput;
};


export type MutationCreateMenuArgs = {
  input: CreateMenuInput;
};


export type MutationCreateOrderArgs = {
  input: CreateOrderInput;
};


export type MutationCreateOrderItemArgs = {
  input: CreateOrderItemInput;
};


export type MutationCreateProductArgs = {
  input: CreateProductInput;
};


export type MutationCreateProductCategoryArgs = {
  input: CreateProductCategoryInput;
};


export type MutationCreatePurchaseArgs = {
  input: CreatePurchaseInput;
};


export type MutationCreatePurchaseItemArgs = {
  input: CreatePurchaseItemInput;
};


export type MutationCreatePurchaseLineItemArgs = {
  input: CreatePurchaseLineItemInput;
};


export type MutationCreateStockArgs = {
  input: CreateStockInput;
};


export type MutationCreateStockItemArgs = {
  input: CreateStockItemInput;
};


export type MutationCreateSupplierArgs = {
  input: CreateSupplierInput;
};


export type MutationCreateUserLocationArgs = {
  input: CreateUserLocationInput;
};


export type MutationCreateVendorArgs = {
  input: CreateVendorInput;
};


export type MutationCreateVendorCustomerArgs = {
  input: CreateVendorCustomerInput;
};


export type MutationDeleteBrandArgs = {
  input: DeleteBrandInput;
};


export type MutationDeleteChoiceGroupArgs = {
  input: DeleteChoiceGroupInput;
};


export type MutationDeleteChoiceGroupItemArgs = {
  input: DeleteChoiceGroupItemInput;
};


export type MutationDeleteCustomerArgs = {
  input: DeleteCustomerInput;
};


export type MutationDeleteDealArgs = {
  input: DeleteDealInput;
};


export type MutationDeleteDiscountArgs = {
  input: DeleteDiscountInput;
};


export type MutationDeleteExpenseArgs = {
  input: DeleteExpenseInput;
};


export type MutationDeleteExpenseItemArgs = {
  input: DeleteExpenseItemInput;
};


export type MutationDeleteFoodAvailabilityArgs = {
  input: DeleteFoodAvailabilityInput;
};


export type MutationDeleteFoodCategoryArgs = {
  input: DeleteFoodCategoryInput;
};


export type MutationDeleteFoodItemArgs = {
  input: DeleteFoodItemInput;
};


export type MutationDeleteFoodItemVariantArgs = {
  input: DeleteFoodItemVariantInput;
};


export type MutationDeleteMenuArgs = {
  input: DeleteMenuInput;
};


export type MutationDeleteOrderArgs = {
  input: DeleteOrderInput;
};


export type MutationDeleteOrderItemArgs = {
  input: DeleteOrderItemInput;
};


export type MutationDeleteProductArgs = {
  input: DeleteProductInput;
};


export type MutationDeleteProductCategoryArgs = {
  input: DeleteProductCategoryInput;
};


export type MutationDeletePurchaseArgs = {
  input: DeletePurchaseInput;
};


export type MutationDeletePurchaseItemArgs = {
  input: DeletePurchaseItemInput;
};


export type MutationDeletePurchaseLineItemArgs = {
  input: DeletePurchaseLineItemInput;
};


export type MutationDeleteStockArgs = {
  input: DeleteStockInput;
};


export type MutationDeleteStockItemArgs = {
  input: DeleteStockItemInput;
};


export type MutationDeleteSupplierArgs = {
  input: DeleteSupplierInput;
};


export type MutationDeleteUserLocationArgs = {
  input: DeleteUserLocationInput;
};


export type MutationDeleteVendorArgs = {
  input: DeleteVendorInput;
};


export type MutationDeleteVendorCustomerArgs = {
  input: DeleteVendorCustomerInput;
};


export type MutationUpdateBrandArgs = {
  input: UpdateBrandInput;
};


export type MutationUpdateChoiceGroupArgs = {
  input: UpdateChoiceGroupInput;
};


export type MutationUpdateChoiceGroupItemArgs = {
  input: UpdateChoiceGroupItemInput;
};


export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};


export type MutationUpdateDealArgs = {
  input: UpdateDealInput;
};


export type MutationUpdateDiscountArgs = {
  input: UpdateDiscountInput;
};


export type MutationUpdateExpenseArgs = {
  input: UpdateExpenseInput;
};


export type MutationUpdateExpenseItemArgs = {
  input: UpdateExpenseItemInput;
};


export type MutationUpdateFoodAvailabilityArgs = {
  input: UpdateFoodAvailabilityInput;
};


export type MutationUpdateFoodCategoryArgs = {
  input: UpdateFoodCategoryInput;
};


export type MutationUpdateFoodItemArgs = {
  input: UpdateFoodItemInput;
};


export type MutationUpdateFoodItemVariantArgs = {
  input: UpdateFoodItemVariantInput;
};


export type MutationUpdateMenuArgs = {
  input: UpdateMenuInput;
};


export type MutationUpdateOrderArgs = {
  input: UpdateOrderInput;
};


export type MutationUpdateOrderItemArgs = {
  input: UpdateOrderItemInput;
};


export type MutationUpdateProductArgs = {
  input: UpdateProductInput;
};


export type MutationUpdateProductCategoryArgs = {
  input: UpdateProductCategoryInput;
};


export type MutationUpdatePurchaseArgs = {
  input: UpdatePurchaseInput;
};


export type MutationUpdatePurchaseItemArgs = {
  input: UpdatePurchaseItemInput;
};


export type MutationUpdatePurchaseLineItemArgs = {
  input: UpdatePurchaseLineItemInput;
};


export type MutationUpdateStockArgs = {
  input: UpdateStockInput;
};


export type MutationUpdateStockItemArgs = {
  input: UpdateStockItemInput;
};


export type MutationUpdateSupplierArgs = {
  input: UpdateSupplierInput;
};


export type MutationUpdateUserLocationArgs = {
  input: UpdateUserLocationInput;
};


export type MutationUpdateVendorArgs = {
  input: UpdateVendorInput;
};


export type MutationUpdateVendorCustomerArgs = {
  input: UpdateVendorCustomerInput;
};

export type Order = {
  __typename?: 'Order';
  acceptedAt?: Maybe<Scalars['ISO8601DateTime']>;
  canceledAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['Int']>;
  deliveredAt?: Maybe<Scalars['ISO8601DateTime']>;
  deliveryFeeAmount: Scalars['Float'];
  discountAmount?: Maybe<Scalars['Float']>;
  discountCode?: Maybe<Scalars['String']>;
  discountedPriceAmount?: Maybe<Scalars['Float']>;
  gstAmount?: Maybe<Scalars['Float']>;
  humanizedOrderType?: Maybe<Scalars['String']>;
  humanizedStatus?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  itemsCount?: Maybe<Scalars['Int']>;
  netTotalAmount: Scalars['Float'];
  orderItems: Array<OrderItem>;
  orderNumber: Scalars['String'];
  orderType?: Maybe<Scalars['String']>;
  pickedAt?: Maybe<Scalars['ISO8601DateTime']>;
  placedFrom?: Maybe<Scalars['String']>;
  receivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  status?: Maybe<Scalars['String']>;
  totalPrice: Scalars['String'];
  totalPriceAmount: Scalars['Float'];
  updatedAt: Scalars['ISO8601DateTime'];
  vendor: Vendor;
  vendorCustomer?: Maybe<VendorCustomer>;
  vendorCustomerId?: Maybe<Scalars['Int']>;
  vendorId?: Maybe<Scalars['Int']>;
};

export type OrderAttributes = {
  acceptedAt?: InputMaybe<Scalars['String']>;
  canceledAt?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['Int']>;
  deliveredAt?: InputMaybe<Scalars['String']>;
  deliveryFeeAmount?: InputMaybe<Scalars['Float']>;
  discountAmount?: InputMaybe<Scalars['Float']>;
  discountCode?: InputMaybe<Scalars['String']>;
  discountedPriceAmount?: InputMaybe<Scalars['Float']>;
  itemsCount?: InputMaybe<Scalars['Int']>;
  orderItems?: InputMaybe<Array<OrderItemAttributes>>;
  orderType?: InputMaybe<Scalars['String']>;
  pickedAt?: InputMaybe<Scalars['String']>;
  placedFrom?: InputMaybe<Scalars['String']>;
  receivedAt?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  totalPriceAmount?: InputMaybe<Scalars['Float']>;
  vendorCustomerId?: InputMaybe<Scalars['Int']>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

export type OrderFilter = {
  OR?: InputMaybe<Array<OrderFilter>>;
  customerId?: InputMaybe<Scalars['Int']>;
  fromDate?: InputMaybe<Scalars['String']>;
  orderNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['String']>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  addons?: Maybe<Array<OrderItem>>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  itemName: Scalars['String'];
  itemQuantity?: Maybe<Scalars['Int']>;
  order?: Maybe<Order>;
  orderId: Scalars['Int'];
  parentId?: Maybe<Scalars['String']>;
  priceAmount: Scalars['Float'];
  priceCurrency: Scalars['String'];
  rateAmount: Scalars['Float'];
  rateCurrency: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
  uuid?: Maybe<Scalars['String']>;
};

export type OrderItemAttributes = {
  itemName: Scalars['String'];
  itemQuantity: Scalars['Int'];
  orderId?: InputMaybe<Scalars['Int']>;
  parentId?: InputMaybe<Scalars['String']>;
  priceAmount?: InputMaybe<Scalars['Float']>;
  priceCurrency?: InputMaybe<Scalars['String']>;
  rateAmount: Scalars['Float'];
  rateCurrency?: InputMaybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type OrderItemFilter = {
  OR?: InputMaybe<Array<OrderItemFilter>>;
  orderId?: InputMaybe<Scalars['String']>;
};

export type OrdersSummary = {
  __typename?: 'OrdersSummary';
  itemsSold: Array<OrdersTotalItem>;
  totalCollection: Scalars['Float'];
  totalItems: Scalars['Int'];
  totalOrders: Scalars['Int'];
};

export type OrdersTotalItem = {
  __typename?: 'OrdersTotalItem';
  itemName: Scalars['String'];
  itemQuantity: Scalars['Float'];
  totalPriceAmount: Scalars['Float'];
};

export type OrdersTotalPrice = {
  __typename?: 'OrdersTotalPrice';
  endDate: Scalars['ISO8601DateTime'];
  startDate: Scalars['ISO8601DateTime'];
  total: Scalars['Float'];
};

export type Pagination = {
  page: Scalars['Int'];
  pageSize?: InputMaybe<Scalars['Int']>;
};

export type Product = {
  __typename?: 'Product';
  brand: Brand;
  brandId: Scalars['Int'];
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  productCategory: ProductCategory;
  productCategoryId: Scalars['Int'];
  status?: Maybe<Scalars['String']>;
  supplier: Supplier;
  supplierId: Scalars['Int'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ProductAttributes = {
  brandId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  productCategoryId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  supplierId?: InputMaybe<Scalars['Int']>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type ProductCategoryAttributes = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ProductCategoryFilter = {
  OR?: InputMaybe<Array<ProductCategoryFilter>>;
  name?: InputMaybe<Scalars['String']>;
};

export type ProductFilter = {
  OR?: InputMaybe<Array<ProductFilter>>;
  brandName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  productCategoryName?: InputMaybe<Scalars['String']>;
  supplierName?: InputMaybe<Scalars['String']>;
};

export type Purchase = {
  __typename?: 'Purchase';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  purchaseItemId?: Maybe<PurchaseItem>;
  purchasedAt?: Maybe<Scalars['ISO8601DateTime']>;
  supplierId?: Maybe<Supplier>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type PurchaseAttributes = {
  purchaseItemId?: InputMaybe<Scalars['Int']>;
  purchasedAt?: InputMaybe<Scalars['String']>;
  supplierId?: InputMaybe<Scalars['Int']>;
};

export type PurchaseFilter = {
  OR?: InputMaybe<Array<PurchaseFilter>>;
  purchaseItemId?: InputMaybe<Scalars['ID']>;
  supplierId?: InputMaybe<Scalars['ID']>;
};

export type PurchaseItem = {
  __typename?: 'PurchaseItem';
  brand?: Maybe<Brand>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type PurchaseItemAttributes = {
  brandId?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type PurchaseItemFilter = {
  OR?: InputMaybe<Array<PurchaseItemFilter>>;
  name?: InputMaybe<Scalars['String']>;
};

export type PurchaseLineItem = {
  __typename?: 'PurchaseLineItem';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  purchase?: Maybe<Purchase>;
  quantity?: Maybe<Scalars['Int']>;
  rateAmount: Scalars['Float'];
  rateCurrency: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type PurchaseLineItemAttributes = {
  purchaseId?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  rateAmount?: InputMaybe<Scalars['Float']>;
  rateCurrency?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
};

export type PurchaseLineItemFilter = {
  OR?: InputMaybe<Array<PurchaseLineItemFilter>>;
  name?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  /** Returns the Brand for a requested id */
  brand?: Maybe<Brand>;
  /** Returns all Brands */
  brands: Array<Brand>;
  canceledOrders: Array<Order>;
  /** Return ChoiceGroup */
  choiceGroup?: Maybe<ChoiceGroup>;
  /** Returns the choice group item for a requested id */
  choiceGroupItem?: Maybe<ChoiceGroupItem>;
  /** Returns all ChoiceGroupItems */
  choiceGroupItems: Array<ChoiceGroupItem>;
  /** Returns all ChoiceGroups */
  choiceGroups: Array<ChoiceGroup>;
  completedOrders: Array<Order>;
  /** Returns the Customer for a requested id */
  customer?: Maybe<Customer>;
  /** Returns all Customers */
  customers: Array<Customer>;
  /** Returns the DailySale for a requested id */
  dailySale?: Maybe<DailySale>;
  /** Returns all DailySales */
  dailySales: Array<DailySale>;
  /** Returns the Deal for a requested id */
  deal?: Maybe<Deal>;
  /** Returns all Deals */
  deals: Array<Deal>;
  /** Returns the Discount for a requested id */
  discount?: Maybe<Discount>;
  /** Returns all Discounts */
  discounts: Array<Discount>;
  /** Returns the Expense for a requested id */
  expense?: Maybe<Expense>;
  /** Returns the ExpenseItem for a requested id */
  expenseItem?: Maybe<ExpenseItem>;
  /** Returns all ExpenseItems */
  expenseItems: Array<ExpenseItem>;
  /** Returns all Expenses */
  expenses: Array<Expense>;
  /** Returns all Expenses */
  expensesByItem: Array<Expense>;
  /** Returns expenses summary */
  expensesSummary: ExpensesSummary;
  /** Returns all Expenses */
  foodAvailabilities: Array<FoodAvailability>;
  /** Returns the food availability for a requested id */
  foodAvailability?: Maybe<FoodAvailability>;
  /** Returns all FoodCategories */
  foodCategories: Array<FoodCategory>;
  /** Returns the FoodCategory for a requested id */
  foodCategory?: Maybe<FoodCategory>;
  /** Returns the FoodItem for a requested id */
  foodItem?: Maybe<FoodItem>;
  /** Returns the FoodItemVariant for a requested id */
  foodItemVariant?: Maybe<FoodItemVariant>;
  /** Returns all FoodItemVariants */
  foodItemVariants: Array<FoodItemVariant>;
  /** Returns all FoodItems */
  foodItems: Array<FoodItem>;
  latestOrders: Array<Order>;
  /** Returns the user for a requested id */
  menu?: Maybe<Menu>;
  /** Returns all menus */
  menus: Array<Menu>;
  /** Returns the Order for a requested id */
  order?: Maybe<Order>;
  /** Returns the OrderItem for a requested id */
  orderItem?: Maybe<OrderItem>;
  /** Returns all OrderItems */
  orderItems: Array<OrderItem>;
  /** Returns all Orders */
  orders: Array<Order>;
  /** Returns orders summary */
  ordersSummary: OrdersSummary;
  /** Returns the orders total price in give date range */
  ordersTotalPrice?: Maybe<OrdersTotalPrice>;
  pendingOrders: Array<Order>;
  pickedOrders: Array<Order>;
  /** Returns the Product for a requested id */
  product?: Maybe<Product>;
  /** Returns all ProductCategories */
  productCategories: Array<ProductCategory>;
  /** Returns the ProductCategory for a requested id */
  productCategory?: Maybe<ProductCategory>;
  /** Returns all Products */
  products: Array<Product>;
  /** Returns the Purchase for a requested id */
  purchase?: Maybe<Purchase>;
  /** Returns the PurchaseItem for a requested id */
  purchaseItem?: Maybe<PurchaseItem>;
  /** Returns all PurchaseItems */
  purchaseItems: Array<PurchaseItem>;
  /** Returns the PurchaseLineItem for a requested id */
  purchaseLineItem?: Maybe<PurchaseLineItem>;
  /** Returns all PurchaseLineItems */
  purchaseLineItems: Array<PurchaseLineItem>;
  /** Returns all Purchases */
  purchases: Array<Purchase>;
  scheduledOrders: Array<Order>;
  /** Returns the Stock for a requested id */
  stock?: Maybe<Stock>;
  /** Returns the StockItem for a requested id */
  stockItem?: Maybe<StockItem>;
  /** Returns all StockItems */
  stockItems: Array<StockItem>;
  /** Returns all Stocks */
  stocks: Array<Stock>;
  /** Returns the Supplier for a requested id */
  supplier?: Maybe<Supplier>;
  /** Returns all Suppliers */
  suppliers: Array<Supplier>;
  /** Returns the total number of expenses for today */
  todayExpensesTotal?: Maybe<TodayExpensesTotal>;
  todayOrders: Array<Order>;
  /** Returns the total number of orders for today */
  todayOrdersCount?: Maybe<TodayOrdersCount>;
  /** Returns the Total Expense in give date range */
  totalExpenses?: Maybe<TotalExpense>;
  /** Returns the Total Expense in give date range */
  totalExpensesByItem?: Maybe<TotalExpensesByItem>;
  /** Returns the user for a requested id */
  user?: Maybe<User>;
  /** Returns the UserLocation for a requested id */
  userLocation?: Maybe<UserLocation>;
  /** Returns all UserLocations */
  userLocations: Array<UserLocation>;
  /** Returns all users */
  users: Array<User>;
  /** Returns the Vendor */
  vendor?: Maybe<Vendor>;
  /** Returns the VendorCustomer for a requested id */
  vendorCustomer?: Maybe<VendorCustomer>;
  /** Returns all VendorCustomers */
  vendorCustomers: Array<VendorCustomer>;
  /** Returns all Vendors */
  vendors: Array<Vendor>;
};


export type QueryBrandArgs = {
  id: Scalars['ID'];
};


export type QueryBrandsArgs = {
  filter?: InputMaybe<BrandFilter>;
  orderBy?: InputMaybe<ItemOrder>;
};


export type QueryCanceledOrdersArgs = {
  filter?: InputMaybe<OrderFilter>;
  orderBy?: InputMaybe<ItemOrder>;
  paginate?: InputMaybe<Pagination>;
};


export type QueryChoiceGroupArgs = {
  id: Scalars['ID'];
};


export type QueryChoiceGroupItemArgs = {
  id: Scalars['ID'];
};


export type QueryChoiceGroupItemsArgs = {
  filter?: InputMaybe<ChoiceGroupItemFilter>;
  orderBy?: InputMaybe<ItemOrder>;
};


export type QueryChoiceGroupsArgs = {
  filter?: InputMaybe<ChoiceGroupFilter>;
  orderBy?: InputMaybe<ItemOrder>;
};


export type QueryCompletedOrdersArgs = {
  filter?: InputMaybe<OrderFilter>;
  orderBy?: InputMaybe<ItemOrder>;
  paginate?: InputMaybe<Pagination>;
};


export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryCustomersArgs = {
  filter?: InputMaybe<CustomerFilter>;
  orderBy?: InputMaybe<ItemOrder>;
};


export type QueryDailySaleArgs = {
  date: Scalars['String'];
};


export type QueryDealArgs = {
  id: Scalars['ID'];
};


export type QueryDealsArgs = {
  filter?: InputMaybe<DealFilter>;
  orderBy?: InputMaybe<ItemOrder>;
};


export type QueryDiscountArgs = {
  id: Scalars['String'];
};


export type QueryDiscountsArgs = {
  filter?: InputMaybe<DiscountFilter>;
  orderBy?: InputMaybe<ItemOrder>;
};


export type QueryExpenseArgs = {
  id: Scalars['ID'];
};


export type QueryExpenseItemArgs = {
  id: Scalars['ID'];
};


export type QueryExpenseItemsArgs = {
  filter?: InputMaybe<ExpenseItemFilter>;
  orderBy?: InputMaybe<ItemOrder>;
};


export type QueryExpensesArgs = {
  filter?: InputMaybe<ExpenseFilter>;
  orderBy?: InputMaybe<ItemOrder>;
};


export type QueryExpensesByItemArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryExpensesSummaryArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryFoodAvailabilitiesArgs = {
  filter?: InputMaybe<FoodAvailabilityFilter>;
  orderBy?: InputMaybe<ItemOrder>;
};


export type QueryFoodAvailabilityArgs = {
  id: Scalars['ID'];
};


export type QueryFoodCategoriesArgs = {
  filter?: InputMaybe<FoodCategoryFilter>;
  orderBy?: InputMaybe<ItemOrder>;
};


export type QueryFoodCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryFoodItemArgs = {
  id: Scalars['ID'];
};


export type QueryFoodItemVariantArgs = {
  id: Scalars['ID'];
};


export type QueryFoodItemVariantsArgs = {
  filter?: InputMaybe<FoodItemVariantFilter>;
  orderBy?: InputMaybe<ItemOrder>;
};


export type QueryFoodItemsArgs = {
  filter?: InputMaybe<FoodItemFilter>;
  orderBy?: InputMaybe<ItemOrder>;
};


export type QueryLatestOrdersArgs = {
  filter?: InputMaybe<OrderFilter>;
  orderBy?: InputMaybe<ItemOrder>;
  paginate?: InputMaybe<Pagination>;
};


export type QueryMenuArgs = {
  id: Scalars['ID'];
};


export type QueryMenusArgs = {
  filter?: InputMaybe<MenuFilter>;
  orderBy?: InputMaybe<ItemOrder>;
};


export type QueryOrderArgs = {
  id: Scalars['ID'];
};


export type QueryOrderItemArgs = {
  id: Scalars['ID'];
};


export type QueryOrderItemsArgs = {
  filter?: InputMaybe<OrderItemFilter>;
  orderBy?: InputMaybe<ItemOrder>;
};


export type QueryOrdersArgs = {
  filter?: InputMaybe<OrderFilter>;
  orderBy?: InputMaybe<ItemOrder>;
  paginate?: InputMaybe<Pagination>;
};


export type QueryOrdersSummaryArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryOrdersTotalPriceArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryPendingOrdersArgs = {
  filter?: InputMaybe<OrderFilter>;
  orderBy?: InputMaybe<ItemOrder>;
  paginate?: InputMaybe<Pagination>;
};


export type QueryPickedOrdersArgs = {
  filter?: InputMaybe<OrderFilter>;
  orderBy?: InputMaybe<ItemOrder>;
  paginate?: InputMaybe<Pagination>;
};


export type QueryProductArgs = {
  id: Scalars['ID'];
};


export type QueryProductCategoriesArgs = {
  filter?: InputMaybe<ProductCategoryFilter>;
  orderBy?: InputMaybe<ItemOrder>;
};


export type QueryProductCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryProductsArgs = {
  filter?: InputMaybe<ProductFilter>;
  orderBy?: InputMaybe<ItemOrder>;
};


export type QueryPurchaseArgs = {
  id: Scalars['ID'];
};


export type QueryPurchaseItemArgs = {
  id: Scalars['ID'];
};


export type QueryPurchaseItemsArgs = {
  filter?: InputMaybe<PurchaseItemFilter>;
  orderBy?: InputMaybe<ItemOrder>;
};


export type QueryPurchaseLineItemArgs = {
  id: Scalars['ID'];
};


export type QueryPurchaseLineItemsArgs = {
  filter?: InputMaybe<PurchaseLineItemFilter>;
  orderBy?: InputMaybe<ItemOrder>;
};


export type QueryPurchasesArgs = {
  filter?: InputMaybe<PurchaseFilter>;
  orderBy?: InputMaybe<ItemOrder>;
};


export type QueryScheduledOrdersArgs = {
  filter?: InputMaybe<OrderFilter>;
  orderBy?: InputMaybe<ItemOrder>;
  paginate?: InputMaybe<Pagination>;
};


export type QueryStockArgs = {
  id: Scalars['ID'];
};


export type QueryStockItemArgs = {
  id: Scalars['ID'];
};


export type QueryStockItemsArgs = {
  filter?: InputMaybe<StockItemFilter>;
  orderBy?: InputMaybe<ItemOrder>;
};


export type QueryStocksArgs = {
  filter?: InputMaybe<StockFilter>;
  orderBy?: InputMaybe<ItemOrder>;
};


export type QuerySupplierArgs = {
  id: Scalars['ID'];
};


export type QuerySuppliersArgs = {
  filter?: InputMaybe<SupplierFilter>;
  orderBy?: InputMaybe<ItemOrder>;
};


export type QueryTodayOrdersArgs = {
  filter?: InputMaybe<OrderFilter>;
  orderBy?: InputMaybe<ItemOrder>;
  paginate?: InputMaybe<Pagination>;
};


export type QueryTotalExpensesArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryTotalExpensesByItemArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUserLocationArgs = {
  id: Scalars['ID'];
};


export type QueryUserLocationsArgs = {
  filter?: InputMaybe<UserLocationFilter>;
  orderBy?: InputMaybe<ItemOrder>;
};


export type QueryUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  orderBy?: InputMaybe<ItemOrder>;
};


export type QueryVendorArgs = {
  id: Scalars['ID'];
};


export type QueryVendorCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryVendorCustomersArgs = {
  filter?: InputMaybe<VendorCustomerFilter>;
  orderBy?: InputMaybe<ItemOrder>;
};


export type QueryVendorsArgs = {
  filter?: InputMaybe<ClientFilter>;
  orderBy?: InputMaybe<ItemOrder>;
};

export type Stock = {
  __typename?: 'Stock';
  address?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  vendor?: Maybe<Vendor>;
  vendorId: Scalars['Int'];
};

export type StockAttributes = {
  address?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

export type StockFilter = {
  OR?: InputMaybe<Array<StockFilter>>;
  name?: InputMaybe<Scalars['String']>;
};

export type StockItem = {
  __typename?: 'StockItem';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  lastPurchasedDate?: Maybe<Scalars['String']>;
  minQuantityRequired?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  stock?: Maybe<Stock>;
  stockId: Scalars['Int'];
  totalPrice?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type StockItemAttributes = {
  lastPurchasedDate?: InputMaybe<Scalars['String']>;
  minQuantityRequired?: InputMaybe<Scalars['String']>;
  productName?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<Scalars['String']>;
  stockId?: InputMaybe<Scalars['Int']>;
  totalPrice?: InputMaybe<Scalars['Float']>;
  unit?: InputMaybe<Scalars['String']>;
  unitPrice?: InputMaybe<Scalars['Float']>;
};

export type StockItemFilter = {
  OR?: InputMaybe<Array<StockItemFilter>>;
  lastPurchasedDate?: InputMaybe<Scalars['String']>;
  productName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  stockId?: InputMaybe<Scalars['Int']>;
};

export type Supplier = {
  __typename?: 'Supplier';
  address?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type SupplierAttributes = {
  address?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type SupplierFilter = {
  OR?: InputMaybe<Array<SupplierFilter>>;
  address?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type TodayExpensesTotal = {
  __typename?: 'TodayExpensesTotal';
  total: Scalars['Int'];
};

export type TodayOrdersCount = {
  __typename?: 'TodayOrdersCount';
  total: Scalars['Int'];
};

export type TotalExpense = {
  __typename?: 'TotalExpense';
  endDate: Scalars['ISO8601DateTime'];
  startDate: Scalars['ISO8601DateTime'];
  total: Scalars['Float'];
};

export type TotalExpensesByItem = {
  __typename?: 'TotalExpensesByItem';
  endDate?: Maybe<Scalars['ISO8601DateTime']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['ISO8601DateTime']>;
  total: Scalars['Float'];
};

/** Autogenerated input type of UpdateBrand */
export type UpdateBrandInput = {
  attributes?: InputMaybe<BrandAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of UpdateChoiceGroup */
export type UpdateChoiceGroupInput = {
  attributes?: InputMaybe<ChoiceGroupAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of UpdateChoiceGroupItem */
export type UpdateChoiceGroupItemInput = {
  attributes?: InputMaybe<ChoiceGroupItemAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of UpdateCustomer */
export type UpdateCustomerInput = {
  attributes?: InputMaybe<CustomerAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of UpdateDeal */
export type UpdateDealInput = {
  attributes?: InputMaybe<DealAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of UpdateDiscount */
export type UpdateDiscountInput = {
  attributes?: InputMaybe<DiscountAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};

/** Autogenerated input type of UpdateExpense */
export type UpdateExpenseInput = {
  attributes?: InputMaybe<ExpenseAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of UpdateExpenseItem */
export type UpdateExpenseItemInput = {
  attributes?: InputMaybe<ExpenseItemAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of UpdateFoodAvailability */
export type UpdateFoodAvailabilityInput = {
  attributes?: InputMaybe<FoodAvailabilityAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of UpdateFoodCategory */
export type UpdateFoodCategoryInput = {
  attributes?: InputMaybe<FoodCategoryAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of UpdateFoodItem */
export type UpdateFoodItemInput = {
  attributes?: InputMaybe<FoodItemAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of UpdateFoodItemVariant */
export type UpdateFoodItemVariantInput = {
  attributes?: InputMaybe<FoodItemVariantAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of UpdateMenu */
export type UpdateMenuInput = {
  attributes?: InputMaybe<MenuAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of UpdateOrder */
export type UpdateOrderInput = {
  attributes?: InputMaybe<OrderAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of UpdateOrderItem */
export type UpdateOrderItemInput = {
  attributes?: InputMaybe<OrderItemAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of UpdateProductCategory */
export type UpdateProductCategoryInput = {
  attributes?: InputMaybe<ProductCategoryAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of UpdateProduct */
export type UpdateProductInput = {
  attributes?: InputMaybe<ProductAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of UpdatePurchase */
export type UpdatePurchaseInput = {
  attributes?: InputMaybe<PurchaseAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of UpdatePurchaseItem */
export type UpdatePurchaseItemInput = {
  attributes?: InputMaybe<PurchaseItemAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of UpdatePurchaseLineItem */
export type UpdatePurchaseLineItemInput = {
  attributes?: InputMaybe<PurchaseLineItemAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of UpdateStock */
export type UpdateStockInput = {
  attributes?: InputMaybe<StockAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of UpdateStockItem */
export type UpdateStockItemInput = {
  attributes?: InputMaybe<StockItemAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of UpdateSupplier */
export type UpdateSupplierInput = {
  attributes?: InputMaybe<SupplierAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of UpdateUserLocation */
export type UpdateUserLocationInput = {
  attributes?: InputMaybe<UserLocationAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of UpdateVendorCustomer */
export type UpdateVendorCustomerInput = {
  attributes?: InputMaybe<VendorCustomerAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated input type of UpdateVendor */
export type UpdateVendorInput = {
  attributes?: InputMaybe<VendorAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['ISO8601DateTime'];
  currentSignInAt?: Maybe<Scalars['ISO8601DateTime']>;
  dob?: Maybe<Scalars['ISO8601Date']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  lastSignInAt?: Maybe<Scalars['ISO8601DateTime']>;
  mobileNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nickname?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type UserFilter = {
  OR?: InputMaybe<Array<UserFilter>>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type UserLocation = {
  __typename?: 'UserLocation';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  customerId: Scalars['Int'];
  id: Scalars['ID'];
  instructions?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type UserLocationAttributes = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['Int']>;
  instructions?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UserLocationFilter = {
  OR?: InputMaybe<Array<UserLocationFilter>>;
  customerId?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

export type Vendor = {
  __typename?: 'Vendor';
  address?: Maybe<Scalars['String']>;
  branches?: Maybe<Array<Vendor>>;
  choiceGroups?: Maybe<Array<ChoiceGroup>>;
  clientType?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  customers?: Maybe<Array<Customer>>;
  email?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  foodCategories?: Maybe<Array<FoodCategory>>;
  id: Scalars['ID'];
  instagram?: Maybe<Scalars['String']>;
  menus?: Maybe<Array<Menu>>;
  name?: Maybe<Scalars['String']>;
  parent?: Maybe<Vendor>;
  parentId?: Maybe<Scalars['Int']>;
  phone?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  uuid?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  whatsapp?: Maybe<Scalars['String']>;
  youtube?: Maybe<Scalars['String']>;
};

export type VendorAttributes = {
  address?: InputMaybe<Scalars['String']>;
  clientType?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  facebook?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['Int']>;
  phone?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  twitter?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
  whatsapp?: InputMaybe<Scalars['String']>;
  youtube?: InputMaybe<Scalars['String']>;
};

export type VendorCustomer = {
  __typename?: 'VendorCustomer';
  createdAt: Scalars['ISO8601DateTime'];
  customerNumber?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
  vendor: Vendor;
  vendorId: Scalars['Int'];
};

export type VendorCustomerAttributes = {
  customerNumber?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['Int']>;
};

export type VendorCustomerFilter = {
  OR?: InputMaybe<Array<VendorCustomerFilter>>;
  name?: InputMaybe<Scalars['String']>;
};

export type CreateExpenseItemMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
}>;


export type CreateExpenseItemMutation = { __typename?: 'Mutation', createExpenseItem?: { __typename?: 'ExpenseItem', id: string, name?: string | null } | null };

export type ExpenseItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type ExpenseItemsQuery = { __typename?: 'Query', expenseItems: Array<{ __typename?: 'ExpenseItem', id: string, name?: string | null, slug?: string | null, latestRateAmount: number, latestRateCurrency: string }> };

export type MenuListQueryVariables = Exact<{ [key: string]: never; }>;


export type MenuListQuery = { __typename?: 'Query', menus: Array<{ __typename?: 'Menu', id: string, name?: string | null, slug?: string | null }> };

export type ProductCategoriesQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
}>;


export type ProductCategoriesQuery = { __typename?: 'Query', productCategories: Array<{ __typename?: 'ProductCategory', id: string, name?: string | null, slug?: string | null, description?: string | null }> };

export type ProductsQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
  productCategoryName?: InputMaybe<Scalars['String']>;
  supplierName?: InputMaybe<Scalars['String']>;
  brandName?: InputMaybe<Scalars['String']>;
}>;


export type ProductsQuery = { __typename?: 'Query', products: Array<{ __typename?: 'Product', id: string, name?: string | null, description?: string | null, productCategoryId: number, status?: string | null, supplierId: number, brandId: number, productCategory: { __typename?: 'ProductCategory', id: string, name?: string | null }, supplier: { __typename?: 'Supplier', id: string, name?: string | null, email?: string | null, phoneNumber?: string | null }, brand: { __typename?: 'Brand', id: string, name?: string | null } }> };

export type StockItemsQueryVariables = Exact<{
  stockId?: InputMaybe<Scalars['Int']>;
  productName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  lastPurchasedDate?: InputMaybe<Scalars['String']>;
}>;


export type StockItemsQuery = { __typename?: 'Query', stockItems: Array<{ __typename?: 'StockItem', id: string, productName?: string | null, quantity?: number | null, unit?: string | null, unitPrice?: number | null, totalPrice?: number | null, minQuantityRequired?: string | null, status?: string | null, lastPurchasedDate?: string | null, stockId: number, stock?: { __typename?: 'Stock', id: string, name?: string | null } | null }> };

export type StocksQueryVariables = Exact<{ [key: string]: never; }>;


export type StocksQuery = { __typename?: 'Query', stocks: Array<{ __typename?: 'Stock', id: string, name?: string | null, address?: string | null, vendorId: number, vendor?: { __typename?: 'Vendor', id: string, name?: string | null } | null }> };

export type SuppliersQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
}>;


export type SuppliersQuery = { __typename?: 'Query', suppliers: Array<{ __typename?: 'Supplier', id: string, name?: string | null, slug?: string | null, address?: string | null, phoneNumber?: string | null, email?: string | null, status?: string | null }> };

export type UpdateBrandMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
}>;


export type UpdateBrandMutation = { __typename?: 'Mutation', updateBrand?: { __typename?: 'Brand', name?: string | null } | null };

export type CreateBrandMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
}>;


export type CreateBrandMutation = { __typename?: 'Mutation', createBrand?: { __typename?: 'Brand', id: string, name?: string | null } | null };

export type CreateCustomerMutationVariables = Exact<{
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
}>;


export type CreateCustomerMutation = { __typename?: 'Mutation', createCustomer?: { __typename?: 'Customer', id: string, status?: string | null, userId: number, vendorId: number, billingStatus?: string | null, customerNumber: number, notes?: string | null, user: { __typename?: 'User', id: string, salutation?: string | null, name: string, firstName?: string | null, lastName?: string | null, mobileNumber?: string | null, email?: string | null, nickname?: string | null, dob?: any | null }, vendor: { __typename?: 'Vendor', id: string, name?: string | null } } | null };

export type CreateDealMutationVariables = Exact<{
  name: Scalars['String'];
  code?: InputMaybe<Scalars['String']>;
  serving: Scalars['Int'];
  totalPriceAmount?: InputMaybe<Scalars['Float']>;
  discountedPriceAmount?: InputMaybe<Scalars['Float']>;
  vendorId: Scalars['Int'];
  dealItems?: InputMaybe<Array<DealItemAttributes> | DealItemAttributes>;
}>;


export type CreateDealMutation = { __typename?: 'Mutation', createDeal?: { __typename?: 'Deal', id: string, name?: string | null, code?: string | null, totalPriceAmount: number, totalPriceCurrency: string, discountedPriceAmount: number, discountedPriceCurrency: string, serving?: number | null, startDate?: any | null, endDate?: any | null, status?: string | null, dealItems?: Array<{ __typename?: 'DealItem', id: string, name?: string | null, quantity?: number | null, priceAmount: number, priceCurrency: string, discountedPriceAmount: number, discountedPriceCurrency: string }> | null } | null };

export type CreateDiscountMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  discountValue?: InputMaybe<Scalars['Float']>;
  discountType?: InputMaybe<Scalars['String']>;
  maxDiscountAmount?: InputMaybe<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
  expiryDate?: InputMaybe<Scalars['ISO8601Date']>;
  openingTime?: InputMaybe<Scalars['String']>;
  closingTime?: InputMaybe<Scalars['String']>;
  daysOfWeek?: InputMaybe<Scalars['String']>;
  minOrderValue?: InputMaybe<Scalars['Float']>;
  priority?: InputMaybe<Scalars['Int']>;
  audience?: InputMaybe<Scalars['String']>;
  applicableOn?: InputMaybe<Scalars['String']>;
  vendorId: Scalars['Int'];
}>;


export type CreateDiscountMutation = { __typename?: 'Mutation', createDiscount?: { __typename?: 'Discount', uuid: string, name?: string | null, code?: string | null, description?: string | null, status?: string | null, discountValue?: number | null, discountType?: string | null, maxDiscountAmount?: number | null, minOrderValue?: number | null, startDate?: any | null, expiryDate?: any | null, openingTime?: string | null, closingTime?: string | null, daysOfWeek?: string | null, priority?: number | null, audience?: string | null, applicableOn?: string | null, vendorId: number, createdAt: any, updatedAt: any } | null };

export type CreateExpenseMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
  rateAmount?: InputMaybe<Scalars['Float']>;
  costAmount?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Float']>;
  unit?: InputMaybe<Scalars['String']>;
  expenseDate: Scalars['String'];
}>;


export type CreateExpenseMutation = { __typename?: 'Mutation', createExpense?: { __typename?: 'Expense', id: string, name?: string | null, rate?: string | null, cost?: string | null, quantityWithUnit?: string | null, formattedExpenseDate?: string | null } | null };

export type CreateOrderMutationVariables = Exact<{
  vendorId?: InputMaybe<Scalars['Int']>;
  vendorCustomerId?: InputMaybe<Scalars['Int']>;
  receivedAt?: InputMaybe<Scalars['String']>;
  orderType?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  deliveryFeeAmount?: InputMaybe<Scalars['Float']>;
  discountAmount?: InputMaybe<Scalars['Float']>;
  discountCode?: InputMaybe<Scalars['String']>;
  discountedPriceAmount?: InputMaybe<Scalars['Float']>;
  orderItems?: InputMaybe<Array<OrderItemAttributes> | OrderItemAttributes>;
}>;


export type CreateOrderMutation = { __typename?: 'Mutation', createOrder?: { __typename?: 'Order', id: string, orderNumber: string, orderType?: string | null, humanizedOrderType?: string | null, status?: string | null, humanizedStatus?: string | null, totalPriceAmount: number, totalPrice: string, deliveryFeeAmount: number, placedFrom?: string | null, itemsCount?: number | null, vendorCustomerId?: number | null, vendorId?: number | null, receivedAt?: any | null, acceptedAt?: any | null, pickedAt?: any | null, deliveredAt?: any | null, canceledAt?: any | null, createdAt: any, updatedAt: any, discountAmount?: number | null, discountCode?: string | null, discountedPriceAmount?: number | null, vendor: { __typename?: 'Vendor', id: string, name?: string | null, uuid?: string | null }, vendorCustomer?: { __typename?: 'VendorCustomer', id: string, firstName?: string | null, lastName?: string | null, email?: string | null, notes?: string | null, phoneNumber?: string | null } | null, orderItems: Array<{ __typename?: 'OrderItem', uuid?: string | null, itemName: string, itemQuantity?: number | null, priceAmount: number, priceCurrency: string, rateAmount: number, rateCurrency: string, parentId?: string | null, orderId: number }> } | null };

export type CreateProductCategoryMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
}>;


export type CreateProductCategoryMutation = { __typename?: 'Mutation', createProductCategory?: { __typename?: 'ProductCategory', id: string, name?: string | null, description?: string | null } | null };

export type CreateProductMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  supplierId?: InputMaybe<Scalars['Int']>;
  brandId?: InputMaybe<Scalars['Int']>;
  productCategoryId?: InputMaybe<Scalars['Int']>;
}>;


export type CreateProductMutation = { __typename?: 'Mutation', createProduct?: { __typename?: 'Product', id: string, name?: string | null, description?: string | null, supplierId: number, productCategoryId: number, brandId: number, supplier: { __typename?: 'Supplier', id: string, name?: string | null, email?: string | null, phoneNumber?: string | null }, productCategory: { __typename?: 'ProductCategory', id: string, name?: string | null }, brand: { __typename?: 'Brand', id: string, name?: string | null } } | null };

export type CreateStockItemMutationVariables = Exact<{
  productName?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Float']>;
  unit?: InputMaybe<Scalars['String']>;
  unitPrice?: InputMaybe<Scalars['Float']>;
  totalPrice?: InputMaybe<Scalars['Float']>;
  minQuantityRequired?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  lastPurchasedDate?: InputMaybe<Scalars['String']>;
  stockId?: InputMaybe<Scalars['Int']>;
}>;


export type CreateStockItemMutation = { __typename?: 'Mutation', createStockItem?: { __typename?: 'StockItem', id: string, productName?: string | null, quantity?: number | null, unit?: string | null, unitPrice?: number | null, totalPrice?: number | null, minQuantityRequired?: string | null, status?: string | null, lastPurchasedDate?: string | null, stockId: number, stock?: { __typename?: 'Stock', id: string, name?: string | null } | null } | null };

export type CreateStockMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
}>;


export type CreateStockMutation = { __typename?: 'Mutation', createStock?: { __typename?: 'Stock', id: string, name?: string | null, address?: string | null, vendorId: number, vendor?: { __typename?: 'Vendor', id: string, name?: string | null } | null } | null };

export type CreateSupplierMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
}>;


export type CreateSupplierMutation = { __typename?: 'Mutation', createSupplier?: { __typename?: 'Supplier', id: string, name?: string | null, email?: string | null, phoneNumber?: string | null } | null };

export type CreateUserLocationMutationVariables = Exact<{
  title?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  instructions?: InputMaybe<Scalars['String']>;
  customerId: Scalars['Int'];
}>;


export type CreateUserLocationMutation = { __typename?: 'Mutation', createUserLocation?: { __typename?: 'UserLocation', id: string, title?: string | null, streetAddress?: string | null, city?: string | null, state?: string | null, country?: string | null, latitude?: string | null, longitude?: string | null, instructions?: string | null, customerId: number, createdAt: any, updatedAt: any } | null };

export type CreateVendorCustomerMutationVariables = Exact<{
  vendorId?: InputMaybe<Scalars['Int']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
}>;


export type CreateVendorCustomerMutation = { __typename?: 'Mutation', createVendorCustomer?: { __typename?: 'VendorCustomer', id: string, firstName?: string | null, lastName?: string | null, phoneNumber?: string | null, email?: string | null, notes?: string | null, vendorId: number, updatedAt: any, createdAt: any } | null };

export type CustomerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CustomerQuery = { __typename?: 'Query', customer?: { __typename?: 'Customer', id: string, status?: string | null, userId: number, vendorId: number, billingStatus?: string | null, customerNumber: number, notes?: string | null, user: { __typename?: 'User', id: string, salutation?: string | null, name: string, firstName?: string | null, lastName?: string | null, mobileNumber?: string | null, email?: string | null, nickname?: string | null, dob?: any | null }, vendor: { __typename?: 'Vendor', id: string, name?: string | null }, userLocations?: Array<{ __typename?: 'UserLocation', id: string, title?: string | null, streetAddress?: string | null, city?: string | null, state?: string | null, country?: string | null, latitude?: string | null, longitude?: string | null, instructions?: string | null, customerId: number, createdAt: any, updatedAt: any }> | null } | null };

export type DailySalesQueryVariables = Exact<{ [key: string]: never; }>;


export type DailySalesQuery = { __typename?: 'Query', dailySales: Array<{ __typename?: 'DailySale', date?: any | null, collectionAmount?: number | null, expenseAmount?: number | null, profit?: number | null, netProfit?: number | null }> };

export type DealsQueryVariables = Exact<{ [key: string]: never; }>;


export type DealsQuery = { __typename?: 'Query', deals: Array<{ __typename?: 'Deal', id: string, name?: string | null, totalPriceAmount: number, totalPriceCurrency: string, discountedPriceAmount: number, discountedPriceCurrency: string, serving?: number | null, startDate?: any | null, endDate?: any | null, status?: string | null, dealItems?: Array<{ __typename?: 'DealItem', id: string, name?: string | null, quantity?: number | null, priceAmount: number, priceCurrency: string, discountedPriceAmount: number, discountedPriceCurrency: string }> | null }> };

export type DeleteDealMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDealMutation = { __typename?: 'Mutation', deleteDeal?: { __typename?: 'Deal', id: string } | null };

export type DeleteUserLocationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserLocationMutation = { __typename?: 'Mutation', deleteUserLocation?: { __typename?: 'UserLocation', id: string } | null };

export type DiscountQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type DiscountQuery = { __typename?: 'Query', discount?: { __typename?: 'Discount', uuid: string, name?: string | null, code?: string | null, description?: string | null, status?: string | null, discountValue?: number | null, discountType?: string | null, maxDiscountAmount?: number | null, minOrderValue?: number | null, startDate?: any | null, expiryDate?: any | null, openingTime?: string | null, closingTime?: string | null, daysOfWeek?: string | null, priority?: number | null, audience?: string | null, applicableOn?: string | null, vendorId: number, createdAt: any, updatedAt: any } | null };

export type DiscountsQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
  vendorId?: InputMaybe<Scalars['Int']>;
}>;


export type DiscountsQuery = { __typename?: 'Query', discounts: Array<{ __typename?: 'Discount', uuid: string, name?: string | null, code?: string | null, description?: string | null, status?: string | null, discountValue?: number | null, discountType?: string | null, maxDiscountAmount?: number | null, minOrderValue?: number | null, startDate?: any | null, expiryDate?: any | null, openingTime?: string | null, closingTime?: string | null, daysOfWeek?: string | null, priority?: number | null, audience?: string | null, applicableOn?: string | null, vendorId: number, createdAt: any, updatedAt: any }> };

export type ExpensesByItemQueryVariables = Exact<{
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
}>;


export type ExpensesByItemQuery = { __typename?: 'Query', expensesByItem: Array<{ __typename?: 'Expense', id: string, name?: string | null, rateAmount: number, rate?: string | null, costAmount: number, cost?: string | null, quantity?: number | null, unit?: string | null, expenseDate?: any | null, formattedExpenseDate?: string | null, quantityWithUnit?: string | null }>, totalExpensesByItem?: { __typename?: 'TotalExpensesByItem', name?: string | null, total: number, startDate?: any | null, endDate?: any | null } | null };

export type ExpensesQueryVariables = Exact<{
  startDate: Scalars['String'];
  endDate: Scalars['String'];
}>;


export type ExpensesQuery = { __typename?: 'Query', expenses: Array<{ __typename?: 'Expense', id: string, name?: string | null, rateAmount: number, rate?: string | null, costAmount: number, cost?: string | null, quantity?: number | null, unit?: string | null, expenseDate?: any | null, formattedExpenseDate?: string | null, quantityWithUnit?: string | null }>, totalExpenses?: { __typename?: 'TotalExpense', total: number, startDate: any, endDate: any } | null };

export type FoodCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type FoodCategoriesQuery = { __typename?: 'Query', foodCategories: Array<{ __typename?: 'FoodCategory', id: string, name: string, foodItems?: Array<{ __typename?: 'FoodItem', id: string, name: string, description?: string | null, priceAmount: number, priceCurrency: string, foodCategoryId?: number | null, menuId?: number | null, foodCategory?: { __typename?: 'FoodCategory', name: string } | null }> | null }> };

export type FoodItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type FoodItemsQuery = { __typename?: 'Query', foodItems: Array<{ __typename?: 'FoodItem', id: string, name: string, description?: string | null, slug?: string | null, priceAmount: number, priceCurrency: string, foodCategory?: { __typename?: 'FoodCategory', name: string } | null }> };

export type MenuQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MenuQuery = { __typename?: 'Query', menu?: { __typename?: 'Menu', id: string, name?: string | null, slug?: string | null, vendor?: { __typename?: 'Vendor', id: string, name?: string | null } | null } | null, foodCategories: Array<{ __typename?: 'FoodCategory', id: string, name: string, foodItems?: Array<{ __typename?: 'FoodItem', id: string, name: string, description?: string | null, priceAmount: number, priceCurrency: string, foodCategoryId?: number | null, menuId?: number | null }> | null }> };

export type OrderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrderQuery = { __typename?: 'Query', order?: { __typename?: 'Order', id: string, orderNumber: string, orderType?: string | null, humanizedOrderType?: string | null, status?: string | null, humanizedStatus?: string | null, totalPriceAmount: number, totalPrice: string, deliveryFeeAmount: number, placedFrom?: string | null, itemsCount?: number | null, vendorCustomerId?: number | null, vendorId?: number | null, receivedAt?: any | null, acceptedAt?: any | null, pickedAt?: any | null, deliveredAt?: any | null, canceledAt?: any | null, createdAt: any, updatedAt: any, discountAmount?: number | null, discountCode?: string | null, discountedPriceAmount?: number | null, gstAmount?: number | null, netTotalAmount: number, vendor: { __typename?: 'Vendor', id: string, name?: string | null, uuid?: string | null }, vendorCustomer?: { __typename?: 'VendorCustomer', id: string, firstName?: string | null, lastName?: string | null, email?: string | null, notes?: string | null, phoneNumber?: string | null } | null, orderItems: Array<{ __typename?: 'OrderItem', uuid?: string | null, itemName: string, itemQuantity?: number | null, priceAmount: number, priceCurrency: string, rateAmount: number, rateCurrency: string, parentId?: string | null, orderId: number }> } | null };

export type OrdersQueryVariables = Exact<{
  page: Scalars['Int'];
  pageSize: Scalars['Int'];
  fromDate?: InputMaybe<Scalars['String']>;
  toDate?: InputMaybe<Scalars['String']>;
}>;


export type OrdersQuery = { __typename?: 'Query', orders: Array<{ __typename?: 'Order', id: string, orderNumber: string, orderType?: string | null, humanizedOrderType?: string | null, status?: string | null, humanizedStatus?: string | null, totalPriceAmount: number, totalPrice: string, deliveryFeeAmount: number, placedFrom?: string | null, itemsCount?: number | null, vendorCustomerId?: number | null, vendorId?: number | null, receivedAt?: any | null, acceptedAt?: any | null, pickedAt?: any | null, deliveredAt?: any | null, canceledAt?: any | null, createdAt: any, updatedAt: any, discountAmount?: number | null, discountCode?: string | null, discountedPriceAmount?: number | null, gstAmount?: number | null, netTotalAmount: number, vendor: { __typename?: 'Vendor', id: string, name?: string | null, uuid?: string | null }, vendorCustomer?: { __typename?: 'VendorCustomer', id: string, firstName?: string | null, lastName?: string | null, email?: string | null, notes?: string | null, phoneNumber?: string | null } | null, orderItems: Array<{ __typename?: 'OrderItem', uuid?: string | null, itemName: string, itemQuantity?: number | null, priceAmount: number, priceCurrency: string, rateAmount: number, rateCurrency: string, parentId?: string | null, orderId: number }> }>, ordersSummary: { __typename?: 'OrdersSummary', totalOrders: number, totalCollection: number, totalItems: number, itemsSold: Array<{ __typename?: 'OrdersTotalItem', itemName: string, itemQuantity: number, totalPriceAmount: number }> }, ordersTotalPrice?: { __typename?: 'OrdersTotalPrice', total: number, startDate: any, endDate: any } | null };

export type BrandListQueryVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
}>;


export type BrandListQuery = { __typename?: 'Query', brands: Array<{ __typename?: 'Brand', id: string, name?: string | null, slug?: string | null }> };

export type DailyStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type DailyStatsQuery = { __typename?: 'Query', todayOrdersCount?: { __typename?: 'TodayOrdersCount', total: number } | null, todayExpensesTotal?: { __typename?: 'TodayExpensesTotal', total: number } | null };

export type UpdateCustomerMutationVariables = Exact<{
  id: Scalars['ID'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['String']>;
  salutation?: InputMaybe<Scalars['String']>;
  billingStatus?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
}>;


export type UpdateCustomerMutation = { __typename?: 'Mutation', updateCustomer?: { __typename?: 'Customer', id: string, status?: string | null, userId: number, vendorId: number, billingStatus?: string | null, customerNumber: number, notes?: string | null, user: { __typename?: 'User', id: string, salutation?: string | null, name: string, firstName?: string | null, lastName?: string | null, mobileNumber?: string | null, email?: string | null, nickname?: string | null, dob?: any | null }, vendor: { __typename?: 'Vendor', id: string, name?: string | null } } | null };

export type UpdateDiscountMutationVariables = Exact<{
  id: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  discountValue?: InputMaybe<Scalars['Float']>;
  discountType?: InputMaybe<Scalars['String']>;
  maxDiscountAmount?: InputMaybe<Scalars['Float']>;
  minOrderValue?: InputMaybe<Scalars['Float']>;
  startDate?: InputMaybe<Scalars['ISO8601Date']>;
  expiryDate?: InputMaybe<Scalars['ISO8601Date']>;
  openingTime?: InputMaybe<Scalars['String']>;
  closingTime?: InputMaybe<Scalars['String']>;
  daysOfWeek?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  audience?: InputMaybe<Scalars['String']>;
  applicableOn?: InputMaybe<Scalars['String']>;
  vendorId: Scalars['Int'];
}>;


export type UpdateDiscountMutation = { __typename?: 'Mutation', updateDiscount?: { __typename?: 'Discount', uuid: string, name?: string | null, code?: string | null, description?: string | null, status?: string | null, discountValue?: number | null, discountType?: string | null, maxDiscountAmount?: number | null, minOrderValue?: number | null, startDate?: any | null, expiryDate?: any | null, openingTime?: string | null, closingTime?: string | null, daysOfWeek?: string | null, priority?: number | null, audience?: string | null, applicableOn?: string | null, vendorId: number, createdAt: any, updatedAt: any } | null };

export type UpdateExpenseMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  rateAmount?: InputMaybe<Scalars['Float']>;
  costAmount?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Float']>;
  unit?: InputMaybe<Scalars['String']>;
  expenseDate?: InputMaybe<Scalars['String']>;
}>;


export type UpdateExpenseMutation = { __typename?: 'Mutation', updateExpense?: { __typename?: 'Expense', id: string, name?: string | null, rateAmount: number, rate?: string | null, costAmount: number, cost?: string | null, quantity?: number | null, unit?: string | null, expenseDate?: any | null, formattedExpenseDate?: string | null, quantityWithUnit?: string | null } | null };

export type UpdateFoodItemMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  priceAmount: Scalars['Float'];
  foodCategoryId: Scalars['Int'];
  menuId: Scalars['Int'];
}>;


export type UpdateFoodItemMutation = { __typename?: 'Mutation', updateFoodItem?: { __typename?: 'FoodItem', id: string, name: string, description?: string | null, slug?: string | null, priceAmount: number, priceCurrency: string, foodCategory?: { __typename?: 'FoodCategory', name: string } | null } | null };

export type UpdateOrderMutationVariables = Exact<{
  id: Scalars['ID'];
  vendorCustomerId?: InputMaybe<Scalars['Int']>;
  receivedAt?: InputMaybe<Scalars['String']>;
  orderType?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  deliveryFeeAmount?: InputMaybe<Scalars['Float']>;
  discountAmount?: InputMaybe<Scalars['Float']>;
  discountCode?: InputMaybe<Scalars['String']>;
  discountedPriceAmount?: InputMaybe<Scalars['Float']>;
  orderItems?: InputMaybe<Array<OrderItemAttributes> | OrderItemAttributes>;
}>;


export type UpdateOrderMutation = { __typename?: 'Mutation', updateOrder?: { __typename?: 'Order', id: string, orderNumber: string, orderType?: string | null, humanizedOrderType?: string | null, status?: string | null, humanizedStatus?: string | null, totalPriceAmount: number, totalPrice: string, deliveryFeeAmount: number, placedFrom?: string | null, itemsCount?: number | null, vendorCustomerId?: number | null, vendorId?: number | null, receivedAt?: any | null, acceptedAt?: any | null, pickedAt?: any | null, deliveredAt?: any | null, canceledAt?: any | null, createdAt: any, updatedAt: any, vendor: { __typename?: 'Vendor', id: string, name?: string | null, uuid?: string | null }, vendorCustomer?: { __typename?: 'VendorCustomer', id: string, firstName?: string | null, lastName?: string | null, email?: string | null, notes?: string | null, phoneNumber?: string | null } | null, orderItems: Array<{ __typename?: 'OrderItem', uuid?: string | null, itemName: string, itemQuantity?: number | null, priceAmount: number, priceCurrency: string, rateAmount: number, rateCurrency: string, parentId?: string | null, orderId: number }> } | null };

export type UpdateProductCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
}>;


export type UpdateProductCategoryMutation = { __typename?: 'Mutation', updateProductCategory?: { __typename?: 'ProductCategory', id: string, name?: string | null, description?: string | null } | null };

export type UpdateProductMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  supplierId?: InputMaybe<Scalars['Int']>;
  brandId?: InputMaybe<Scalars['Int']>;
  productCategoryId?: InputMaybe<Scalars['Int']>;
}>;


export type UpdateProductMutation = { __typename?: 'Mutation', updateProduct?: { __typename?: 'Product', id: string, name?: string | null, description?: string | null, supplierId: number, productCategoryId: number, brandId: number, supplier: { __typename?: 'Supplier', id: string, name?: string | null, email?: string | null, phoneNumber?: string | null }, productCategory: { __typename?: 'ProductCategory', id: string, name?: string | null }, brand: { __typename?: 'Brand', id: string, name?: string | null } } | null };

export type UpdateStockItemMutationVariables = Exact<{
  id: Scalars['ID'];
  quantity?: InputMaybe<Scalars['Float']>;
  unit?: InputMaybe<Scalars['String']>;
  unitPrice?: InputMaybe<Scalars['Float']>;
  totalPrice?: InputMaybe<Scalars['Float']>;
  minQuantityRequired?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  lastPurchasedDate?: InputMaybe<Scalars['String']>;
  stockId?: InputMaybe<Scalars['Int']>;
}>;


export type UpdateStockItemMutation = { __typename?: 'Mutation', updateStockItem?: { __typename?: 'StockItem', id: string, productName?: string | null, quantity?: number | null, unit?: string | null, unitPrice?: number | null, totalPrice?: number | null, minQuantityRequired?: string | null, status?: string | null, lastPurchasedDate?: string | null, stockId: number, stock?: { __typename?: 'Stock', id: string, name?: string | null } | null } | null };

export type UpdateSupplierMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  address?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
}>;


export type UpdateSupplierMutation = { __typename?: 'Mutation', updateSupplier?: { __typename?: 'Supplier', id: string, name?: string | null, address?: string | null, phoneNumber?: string | null, status?: string | null, email?: string | null } | null };

export type UpdateUserLocationMutationVariables = Exact<{
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
  streetAddress?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  instructions?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['Int']>;
}>;


export type UpdateUserLocationMutation = { __typename?: 'Mutation', updateUserLocation?: { __typename?: 'UserLocation', id: string, title?: string | null, streetAddress?: string | null, city?: string | null, state?: string | null, country?: string | null, latitude?: string | null, longitude?: string | null, instructions?: string | null, customerId: number, createdAt: any, updatedAt: any } | null };

export type UpdateVendorCustomerMutationVariables = Exact<{
  id: Scalars['ID'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
}>;


export type UpdateVendorCustomerMutation = { __typename?: 'Mutation', updateVendorCustomer?: { __typename?: 'VendorCustomer', id: string, firstName?: string | null, lastName?: string | null, phoneNumber?: string | null, email?: string | null, notes?: string | null, vendorId: number, createdAt: any, updatedAt: any } | null };

export type UserLocationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserLocationQuery = { __typename?: 'Query', userLocation?: { __typename?: 'UserLocation', id: string, title?: string | null, streetAddress?: string | null, city?: string | null, state?: string | null, country?: string | null, latitude?: string | null, longitude?: string | null, instructions?: string | null, customerId: number, createdAt: any, updatedAt: any } | null };

export type UserLocationsQueryVariables = Exact<{
  customerId: Scalars['Int'];
}>;


export type UserLocationsQuery = { __typename?: 'Query', userLocations: Array<{ __typename?: 'UserLocation', id: string, title?: string | null, streetAddress?: string | null, city?: string | null, state?: string | null, country?: string | null, latitude?: string | null, longitude?: string | null, instructions?: string | null, customerId: number, createdAt: any, updatedAt: any }> };

export type VendorCustomersQueryVariables = Exact<{ [key: string]: never; }>;


export type VendorCustomersQuery = { __typename?: 'Query', vendorCustomers: Array<{ __typename?: 'VendorCustomer', id: string, firstName?: string | null, lastName?: string | null, phoneNumber?: string | null, email?: string | null, notes?: string | null, vendorId: number, createdAt: any, updatedAt: any }> };

export type VendorsQueryVariables = Exact<{ [key: string]: never; }>;


export type VendorsQuery = { __typename?: 'Query', vendors: Array<{ __typename?: 'Vendor', id: string, name?: string | null, slug?: string | null, clientType?: string | null, address?: string | null, state?: string | null, country?: string | null, phone?: string | null, email?: string | null, website?: string | null, parentId?: number | null }> };


export const CreateExpenseItemDocument = gql`
    mutation CreateExpenseItem($name: String) {
  createExpenseItem(input: {attributes: {name: $name}}) {
    id
    name
  }
}
    `;
export type CreateExpenseItemMutationFn = Apollo.MutationFunction<CreateExpenseItemMutation, CreateExpenseItemMutationVariables>;

/**
 * __useCreateExpenseItemMutation__
 *
 * To run a mutation, you first call `useCreateExpenseItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExpenseItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExpenseItemMutation, { data, loading, error }] = useCreateExpenseItemMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateExpenseItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateExpenseItemMutation, CreateExpenseItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateExpenseItemMutation, CreateExpenseItemMutationVariables>(CreateExpenseItemDocument, options);
      }
export type CreateExpenseItemMutationHookResult = ReturnType<typeof useCreateExpenseItemMutation>;
export type CreateExpenseItemMutationResult = Apollo.MutationResult<CreateExpenseItemMutation>;
export type CreateExpenseItemMutationOptions = Apollo.BaseMutationOptions<CreateExpenseItemMutation, CreateExpenseItemMutationVariables>;
export const ExpenseItemsDocument = gql`
    query ExpenseItems {
  expenseItems {
    id
    name
    slug
    latestRateAmount
    latestRateCurrency
  }
}
    `;

/**
 * __useExpenseItemsQuery__
 *
 * To run a query within a React component, call `useExpenseItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpenseItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpenseItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useExpenseItemsQuery(baseOptions?: Apollo.QueryHookOptions<ExpenseItemsQuery, ExpenseItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpenseItemsQuery, ExpenseItemsQueryVariables>(ExpenseItemsDocument, options);
      }
export function useExpenseItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpenseItemsQuery, ExpenseItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpenseItemsQuery, ExpenseItemsQueryVariables>(ExpenseItemsDocument, options);
        }
export type ExpenseItemsQueryHookResult = ReturnType<typeof useExpenseItemsQuery>;
export type ExpenseItemsLazyQueryHookResult = ReturnType<typeof useExpenseItemsLazyQuery>;
export type ExpenseItemsQueryResult = Apollo.QueryResult<ExpenseItemsQuery, ExpenseItemsQueryVariables>;
export const MenuListDocument = gql`
    query MenuList {
  menus {
    id
    name
    slug
  }
}
    `;

/**
 * __useMenuListQuery__
 *
 * To run a query within a React component, call `useMenuListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuListQuery({
 *   variables: {
 *   },
 * });
 */
export function useMenuListQuery(baseOptions?: Apollo.QueryHookOptions<MenuListQuery, MenuListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MenuListQuery, MenuListQueryVariables>(MenuListDocument, options);
      }
export function useMenuListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuListQuery, MenuListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MenuListQuery, MenuListQueryVariables>(MenuListDocument, options);
        }
export type MenuListQueryHookResult = ReturnType<typeof useMenuListQuery>;
export type MenuListLazyQueryHookResult = ReturnType<typeof useMenuListLazyQuery>;
export type MenuListQueryResult = Apollo.QueryResult<MenuListQuery, MenuListQueryVariables>;
export const ProductCategoriesDocument = gql`
    query ProductCategories($name: String) {
  productCategories(filter: {name: $name}) {
    id
    name
    slug
    description
  }
}
    `;

/**
 * __useProductCategoriesQuery__
 *
 * To run a query within a React component, call `useProductCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductCategoriesQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useProductCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<ProductCategoriesQuery, ProductCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductCategoriesQuery, ProductCategoriesQueryVariables>(ProductCategoriesDocument, options);
      }
export function useProductCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductCategoriesQuery, ProductCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductCategoriesQuery, ProductCategoriesQueryVariables>(ProductCategoriesDocument, options);
        }
export type ProductCategoriesQueryHookResult = ReturnType<typeof useProductCategoriesQuery>;
export type ProductCategoriesLazyQueryHookResult = ReturnType<typeof useProductCategoriesLazyQuery>;
export type ProductCategoriesQueryResult = Apollo.QueryResult<ProductCategoriesQuery, ProductCategoriesQueryVariables>;
export const ProductsDocument = gql`
    query Products($name: String, $productCategoryName: String, $supplierName: String, $brandName: String) {
  products(
    filter: {name: $name, productCategoryName: $productCategoryName, supplierName: $supplierName, brandName: $brandName}
  ) {
    id
    name
    description
    productCategoryId
    productCategory {
      id
      name
    }
    status
    supplierId
    supplier {
      id
      name
      email
      phoneNumber
    }
    brandId
    brand {
      id
      name
    }
  }
}
    `;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      productCategoryName: // value for 'productCategoryName'
 *      supplierName: // value for 'supplierName'
 *      brandName: // value for 'brandName'
 *   },
 * });
 */
export function useProductsQuery(baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
      }
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const StockItemsDocument = gql`
    query StockItems($stockId: Int, $productName: String, $status: String, $lastPurchasedDate: String) {
  stockItems(
    filter: {stockId: $stockId, productName: $productName, status: $status, lastPurchasedDate: $lastPurchasedDate}
  ) {
    id
    productName
    quantity
    unit
    unitPrice
    totalPrice
    minQuantityRequired
    status
    lastPurchasedDate
    stockId
    stock {
      id
      name
    }
  }
}
    `;

/**
 * __useStockItemsQuery__
 *
 * To run a query within a React component, call `useStockItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockItemsQuery({
 *   variables: {
 *      stockId: // value for 'stockId'
 *      productName: // value for 'productName'
 *      status: // value for 'status'
 *      lastPurchasedDate: // value for 'lastPurchasedDate'
 *   },
 * });
 */
export function useStockItemsQuery(baseOptions?: Apollo.QueryHookOptions<StockItemsQuery, StockItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StockItemsQuery, StockItemsQueryVariables>(StockItemsDocument, options);
      }
export function useStockItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StockItemsQuery, StockItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StockItemsQuery, StockItemsQueryVariables>(StockItemsDocument, options);
        }
export type StockItemsQueryHookResult = ReturnType<typeof useStockItemsQuery>;
export type StockItemsLazyQueryHookResult = ReturnType<typeof useStockItemsLazyQuery>;
export type StockItemsQueryResult = Apollo.QueryResult<StockItemsQuery, StockItemsQueryVariables>;
export const StocksDocument = gql`
    query Stocks {
  stocks {
    id
    name
    address
    vendorId
    vendor {
      id
      name
    }
  }
}
    `;

/**
 * __useStocksQuery__
 *
 * To run a query within a React component, call `useStocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useStocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStocksQuery({
 *   variables: {
 *   },
 * });
 */
export function useStocksQuery(baseOptions?: Apollo.QueryHookOptions<StocksQuery, StocksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StocksQuery, StocksQueryVariables>(StocksDocument, options);
      }
export function useStocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StocksQuery, StocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StocksQuery, StocksQueryVariables>(StocksDocument, options);
        }
export type StocksQueryHookResult = ReturnType<typeof useStocksQuery>;
export type StocksLazyQueryHookResult = ReturnType<typeof useStocksLazyQuery>;
export type StocksQueryResult = Apollo.QueryResult<StocksQuery, StocksQueryVariables>;
export const SuppliersDocument = gql`
    query Suppliers($name: String) {
  suppliers(filter: {name: $name}) {
    id
    name
    slug
    address
    phoneNumber
    email
    status
  }
}
    `;

/**
 * __useSuppliersQuery__
 *
 * To run a query within a React component, call `useSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuppliersQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSuppliersQuery(baseOptions?: Apollo.QueryHookOptions<SuppliersQuery, SuppliersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuppliersQuery, SuppliersQueryVariables>(SuppliersDocument, options);
      }
export function useSuppliersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuppliersQuery, SuppliersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuppliersQuery, SuppliersQueryVariables>(SuppliersDocument, options);
        }
export type SuppliersQueryHookResult = ReturnType<typeof useSuppliersQuery>;
export type SuppliersLazyQueryHookResult = ReturnType<typeof useSuppliersLazyQuery>;
export type SuppliersQueryResult = Apollo.QueryResult<SuppliersQuery, SuppliersQueryVariables>;
export const UpdateBrandDocument = gql`
    mutation UpdateBrand($id: ID!, $name: String) {
  updateBrand(input: {id: $id, attributes: {name: $name}}) {
    name
  }
}
    `;
export type UpdateBrandMutationFn = Apollo.MutationFunction<UpdateBrandMutation, UpdateBrandMutationVariables>;

/**
 * __useUpdateBrandMutation__
 *
 * To run a mutation, you first call `useUpdateBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBrandMutation, { data, loading, error }] = useUpdateBrandMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateBrandMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBrandMutation, UpdateBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBrandMutation, UpdateBrandMutationVariables>(UpdateBrandDocument, options);
      }
export type UpdateBrandMutationHookResult = ReturnType<typeof useUpdateBrandMutation>;
export type UpdateBrandMutationResult = Apollo.MutationResult<UpdateBrandMutation>;
export type UpdateBrandMutationOptions = Apollo.BaseMutationOptions<UpdateBrandMutation, UpdateBrandMutationVariables>;
export const CreateBrandDocument = gql`
    mutation CreateBrand($name: String) {
  createBrand(input: {attributes: {name: $name}}) {
    id
    name
  }
}
    `;
export type CreateBrandMutationFn = Apollo.MutationFunction<CreateBrandMutation, CreateBrandMutationVariables>;

/**
 * __useCreateBrandMutation__
 *
 * To run a mutation, you first call `useCreateBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBrandMutation, { data, loading, error }] = useCreateBrandMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateBrandMutation(baseOptions?: Apollo.MutationHookOptions<CreateBrandMutation, CreateBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBrandMutation, CreateBrandMutationVariables>(CreateBrandDocument, options);
      }
export type CreateBrandMutationHookResult = ReturnType<typeof useCreateBrandMutation>;
export type CreateBrandMutationResult = Apollo.MutationResult<CreateBrandMutation>;
export type CreateBrandMutationOptions = Apollo.BaseMutationOptions<CreateBrandMutation, CreateBrandMutationVariables>;
export const CreateCustomerDocument = gql`
    mutation CreateCustomer($firstName: String, $lastName: String, $mobileNumber: String) {
  createCustomer(
    input: {attributes: {firstName: $firstName, lastName: $lastName, mobileNumber: $mobileNumber}}
  ) {
    id
    status
    userId
    user {
      id
      salutation
      name
      firstName
      lastName
      mobileNumber
      email
      nickname
      dob
    }
    vendorId
    vendor {
      id
      name
    }
    billingStatus
    customerNumber
    notes
  }
}
    `;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      mobileNumber: // value for 'mobileNumber'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, options);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const CreateDealDocument = gql`
    mutation CreateDeal($name: String!, $code: String, $serving: Int!, $totalPriceAmount: Float, $discountedPriceAmount: Float, $vendorId: Int!, $dealItems: [DealItemAttributes!]) {
  createDeal(
    input: {attributes: {name: $name, code: $code, serving: $serving, totalPriceAmount: $totalPriceAmount, discountedPriceAmount: $discountedPriceAmount, vendorId: $vendorId, dealItems: $dealItems}}
  ) {
    id
    name
    code
    totalPriceAmount
    totalPriceCurrency
    discountedPriceAmount
    discountedPriceCurrency
    serving
    startDate
    endDate
    status
    dealItems {
      id
      name
      quantity
      priceAmount
      priceCurrency
      discountedPriceAmount
      discountedPriceCurrency
    }
  }
}
    `;
export type CreateDealMutationFn = Apollo.MutationFunction<CreateDealMutation, CreateDealMutationVariables>;

/**
 * __useCreateDealMutation__
 *
 * To run a mutation, you first call `useCreateDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDealMutation, { data, loading, error }] = useCreateDealMutation({
 *   variables: {
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      serving: // value for 'serving'
 *      totalPriceAmount: // value for 'totalPriceAmount'
 *      discountedPriceAmount: // value for 'discountedPriceAmount'
 *      vendorId: // value for 'vendorId'
 *      dealItems: // value for 'dealItems'
 *   },
 * });
 */
export function useCreateDealMutation(baseOptions?: Apollo.MutationHookOptions<CreateDealMutation, CreateDealMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDealMutation, CreateDealMutationVariables>(CreateDealDocument, options);
      }
export type CreateDealMutationHookResult = ReturnType<typeof useCreateDealMutation>;
export type CreateDealMutationResult = Apollo.MutationResult<CreateDealMutation>;
export type CreateDealMutationOptions = Apollo.BaseMutationOptions<CreateDealMutation, CreateDealMutationVariables>;
export const CreateDiscountDocument = gql`
    mutation CreateDiscount($name: String, $code: String, $description: String, $status: String, $discountValue: Float, $discountType: String, $maxDiscountAmount: Float, $startDate: ISO8601Date, $expiryDate: ISO8601Date, $openingTime: String, $closingTime: String, $daysOfWeek: String, $minOrderValue: Float, $priority: Int, $audience: String, $applicableOn: String, $vendorId: Int!) {
  createDiscount(
    input: {attributes: {name: $name, code: $code, description: $description, status: $status, discountValue: $discountValue, discountType: $discountType, maxDiscountAmount: $maxDiscountAmount, startDate: $startDate, expiryDate: $expiryDate, openingTime: $openingTime, closingTime: $closingTime, daysOfWeek: $daysOfWeek, minOrderValue: $minOrderValue, priority: $priority, audience: $audience, applicableOn: $applicableOn, vendorId: $vendorId}}
  ) {
    uuid
    name
    code
    description
    status
    discountValue
    discountType
    maxDiscountAmount
    minOrderValue
    startDate
    expiryDate
    openingTime
    closingTime
    daysOfWeek
    priority
    audience
    applicableOn
    vendorId
    createdAt
    updatedAt
  }
}
    `;
export type CreateDiscountMutationFn = Apollo.MutationFunction<CreateDiscountMutation, CreateDiscountMutationVariables>;

/**
 * __useCreateDiscountMutation__
 *
 * To run a mutation, you first call `useCreateDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDiscountMutation, { data, loading, error }] = useCreateDiscountMutation({
 *   variables: {
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      description: // value for 'description'
 *      status: // value for 'status'
 *      discountValue: // value for 'discountValue'
 *      discountType: // value for 'discountType'
 *      maxDiscountAmount: // value for 'maxDiscountAmount'
 *      startDate: // value for 'startDate'
 *      expiryDate: // value for 'expiryDate'
 *      openingTime: // value for 'openingTime'
 *      closingTime: // value for 'closingTime'
 *      daysOfWeek: // value for 'daysOfWeek'
 *      minOrderValue: // value for 'minOrderValue'
 *      priority: // value for 'priority'
 *      audience: // value for 'audience'
 *      applicableOn: // value for 'applicableOn'
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useCreateDiscountMutation(baseOptions?: Apollo.MutationHookOptions<CreateDiscountMutation, CreateDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDiscountMutation, CreateDiscountMutationVariables>(CreateDiscountDocument, options);
      }
export type CreateDiscountMutationHookResult = ReturnType<typeof useCreateDiscountMutation>;
export type CreateDiscountMutationResult = Apollo.MutationResult<CreateDiscountMutation>;
export type CreateDiscountMutationOptions = Apollo.BaseMutationOptions<CreateDiscountMutation, CreateDiscountMutationVariables>;
export const CreateExpenseDocument = gql`
    mutation CreateExpense($name: String, $rateAmount: Float, $costAmount: Float, $quantity: Float, $unit: String, $expenseDate: String!) {
  createExpense(
    input: {attributes: {name: $name, rateAmount: $rateAmount, rateCurrency: "PKR", costAmount: $costAmount, costCurrency: "PKR", quantity: $quantity, unit: $unit, expenseDate: $expenseDate}}
  ) {
    id
    name
    rate
    cost
    quantityWithUnit
    formattedExpenseDate
  }
}
    `;
export type CreateExpenseMutationFn = Apollo.MutationFunction<CreateExpenseMutation, CreateExpenseMutationVariables>;

/**
 * __useCreateExpenseMutation__
 *
 * To run a mutation, you first call `useCreateExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExpenseMutation, { data, loading, error }] = useCreateExpenseMutation({
 *   variables: {
 *      name: // value for 'name'
 *      rateAmount: // value for 'rateAmount'
 *      costAmount: // value for 'costAmount'
 *      quantity: // value for 'quantity'
 *      unit: // value for 'unit'
 *      expenseDate: // value for 'expenseDate'
 *   },
 * });
 */
export function useCreateExpenseMutation(baseOptions?: Apollo.MutationHookOptions<CreateExpenseMutation, CreateExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateExpenseMutation, CreateExpenseMutationVariables>(CreateExpenseDocument, options);
      }
export type CreateExpenseMutationHookResult = ReturnType<typeof useCreateExpenseMutation>;
export type CreateExpenseMutationResult = Apollo.MutationResult<CreateExpenseMutation>;
export type CreateExpenseMutationOptions = Apollo.BaseMutationOptions<CreateExpenseMutation, CreateExpenseMutationVariables>;
export const CreateOrderDocument = gql`
    mutation CreateOrder($vendorId: Int, $vendorCustomerId: Int, $receivedAt: String, $orderType: String, $status: String, $deliveryFeeAmount: Float, $discountAmount: Float, $discountCode: String, $discountedPriceAmount: Float, $orderItems: [OrderItemAttributes!]) {
  createOrder(
    input: {attributes: {orderItems: $orderItems, vendorId: $vendorId, vendorCustomerId: $vendorCustomerId, receivedAt: $receivedAt, orderType: $orderType, status: $status, deliveryFeeAmount: $deliveryFeeAmount, discountAmount: $discountAmount, discountCode: $discountCode, discountedPriceAmount: $discountedPriceAmount}}
  ) {
    id
    orderNumber
    orderType
    humanizedOrderType
    status
    humanizedStatus
    totalPriceAmount
    totalPrice
    deliveryFeeAmount
    placedFrom
    itemsCount
    vendorCustomerId
    vendorId
    receivedAt
    acceptedAt
    pickedAt
    deliveredAt
    canceledAt
    createdAt
    updatedAt
    discountAmount
    discountCode
    discountedPriceAmount
    vendor {
      id
      name
      uuid
    }
    vendorCustomer {
      id
      firstName
      lastName
      email
      notes
      phoneNumber
    }
    orderItems {
      uuid
      itemName
      itemQuantity
      priceAmount
      priceCurrency
      rateAmount
      rateCurrency
      parentId
      orderId
    }
  }
}
    `;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;

/**
 * __useCreateOrderMutation__
 *
 * To run a mutation, you first call `useCreateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderMutation, { data, loading, error }] = useCreateOrderMutation({
 *   variables: {
 *      vendorId: // value for 'vendorId'
 *      vendorCustomerId: // value for 'vendorCustomerId'
 *      receivedAt: // value for 'receivedAt'
 *      orderType: // value for 'orderType'
 *      status: // value for 'status'
 *      deliveryFeeAmount: // value for 'deliveryFeeAmount'
 *      discountAmount: // value for 'discountAmount'
 *      discountCode: // value for 'discountCode'
 *      discountedPriceAmount: // value for 'discountedPriceAmount'
 *      orderItems: // value for 'orderItems'
 *   },
 * });
 */
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export type CreateOrderMutationOptions = Apollo.BaseMutationOptions<CreateOrderMutation, CreateOrderMutationVariables>;
export const CreateProductCategoryDocument = gql`
    mutation CreateProductCategory($name: String, $description: String) {
  createProductCategory(
    input: {attributes: {name: $name, description: $description}}
  ) {
    id
    name
    description
  }
}
    `;
export type CreateProductCategoryMutationFn = Apollo.MutationFunction<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>;

/**
 * __useCreateProductCategoryMutation__
 *
 * To run a mutation, you first call `useCreateProductCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductCategoryMutation, { data, loading, error }] = useCreateProductCategoryMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateProductCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>(CreateProductCategoryDocument, options);
      }
export type CreateProductCategoryMutationHookResult = ReturnType<typeof useCreateProductCategoryMutation>;
export type CreateProductCategoryMutationResult = Apollo.MutationResult<CreateProductCategoryMutation>;
export type CreateProductCategoryMutationOptions = Apollo.BaseMutationOptions<CreateProductCategoryMutation, CreateProductCategoryMutationVariables>;
export const CreateProductDocument = gql`
    mutation CreateProduct($name: String, $description: String, $supplierId: Int, $brandId: Int, $productCategoryId: Int) {
  createProduct(
    input: {attributes: {name: $name, description: $description, supplierId: $supplierId, brandId: $brandId, productCategoryId: $productCategoryId}}
  ) {
    id
    name
    description
    supplierId
    supplier {
      id
      name
      email
      phoneNumber
    }
    productCategoryId
    productCategory {
      id
      name
    }
    brandId
    brand {
      id
      name
    }
  }
}
    `;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      supplierId: // value for 'supplierId'
 *      brandId: // value for 'brandId'
 *      productCategoryId: // value for 'productCategoryId'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, options);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const CreateStockItemDocument = gql`
    mutation CreateStockItem($productName: String, $quantity: Float, $unit: String, $unitPrice: Float, $totalPrice: Float, $minQuantityRequired: String, $status: String, $lastPurchasedDate: String, $stockId: Int) {
  createStockItem(
    input: {attributes: {productName: $productName, quantity: $quantity, unit: $unit, unitPrice: $unitPrice, totalPrice: $totalPrice, minQuantityRequired: $minQuantityRequired, status: $status, lastPurchasedDate: $lastPurchasedDate, stockId: $stockId}}
  ) {
    id
    productName
    quantity
    unit
    unitPrice
    totalPrice
    minQuantityRequired
    status
    lastPurchasedDate
    stockId
    stock {
      id
      name
    }
  }
}
    `;
export type CreateStockItemMutationFn = Apollo.MutationFunction<CreateStockItemMutation, CreateStockItemMutationVariables>;

/**
 * __useCreateStockItemMutation__
 *
 * To run a mutation, you first call `useCreateStockItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockItemMutation, { data, loading, error }] = useCreateStockItemMutation({
 *   variables: {
 *      productName: // value for 'productName'
 *      quantity: // value for 'quantity'
 *      unit: // value for 'unit'
 *      unitPrice: // value for 'unitPrice'
 *      totalPrice: // value for 'totalPrice'
 *      minQuantityRequired: // value for 'minQuantityRequired'
 *      status: // value for 'status'
 *      lastPurchasedDate: // value for 'lastPurchasedDate'
 *      stockId: // value for 'stockId'
 *   },
 * });
 */
export function useCreateStockItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateStockItemMutation, CreateStockItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStockItemMutation, CreateStockItemMutationVariables>(CreateStockItemDocument, options);
      }
export type CreateStockItemMutationHookResult = ReturnType<typeof useCreateStockItemMutation>;
export type CreateStockItemMutationResult = Apollo.MutationResult<CreateStockItemMutation>;
export type CreateStockItemMutationOptions = Apollo.BaseMutationOptions<CreateStockItemMutation, CreateStockItemMutationVariables>;
export const CreateStockDocument = gql`
    mutation CreateStock($name: String, $address: String) {
  createStock(input: {attributes: {name: $name, address: $address}}) {
    id
    name
    address
    vendorId
    vendor {
      id
      name
    }
  }
}
    `;
export type CreateStockMutationFn = Apollo.MutationFunction<CreateStockMutation, CreateStockMutationVariables>;

/**
 * __useCreateStockMutation__
 *
 * To run a mutation, you first call `useCreateStockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStockMutation, { data, loading, error }] = useCreateStockMutation({
 *   variables: {
 *      name: // value for 'name'
 *      address: // value for 'address'
 *   },
 * });
 */
export function useCreateStockMutation(baseOptions?: Apollo.MutationHookOptions<CreateStockMutation, CreateStockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStockMutation, CreateStockMutationVariables>(CreateStockDocument, options);
      }
export type CreateStockMutationHookResult = ReturnType<typeof useCreateStockMutation>;
export type CreateStockMutationResult = Apollo.MutationResult<CreateStockMutation>;
export type CreateStockMutationOptions = Apollo.BaseMutationOptions<CreateStockMutation, CreateStockMutationVariables>;
export const CreateSupplierDocument = gql`
    mutation CreateSupplier($name: String, $email: String, $phoneNumber: String) {
  createSupplier(
    input: {attributes: {name: $name, email: $email, phoneNumber: $phoneNumber}}
  ) {
    id
    name
    email
    phoneNumber
  }
}
    `;
export type CreateSupplierMutationFn = Apollo.MutationFunction<CreateSupplierMutation, CreateSupplierMutationVariables>;

/**
 * __useCreateSupplierMutation__
 *
 * To run a mutation, you first call `useCreateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierMutation, { data, loading, error }] = useCreateSupplierMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useCreateSupplierMutation(baseOptions?: Apollo.MutationHookOptions<CreateSupplierMutation, CreateSupplierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSupplierMutation, CreateSupplierMutationVariables>(CreateSupplierDocument, options);
      }
export type CreateSupplierMutationHookResult = ReturnType<typeof useCreateSupplierMutation>;
export type CreateSupplierMutationResult = Apollo.MutationResult<CreateSupplierMutation>;
export type CreateSupplierMutationOptions = Apollo.BaseMutationOptions<CreateSupplierMutation, CreateSupplierMutationVariables>;
export const CreateUserLocationDocument = gql`
    mutation CreateUserLocation($title: String, $streetAddress: String, $city: String, $state: String, $country: String, $latitude: String, $longitude: String, $instructions: String, $customerId: Int!) {
  createUserLocation(
    input: {attributes: {title: $title, streetAddress: $streetAddress, city: $city, state: $state, country: $country, latitude: $latitude, longitude: $longitude, instructions: $instructions, customerId: $customerId}}
  ) {
    id
    title
    streetAddress
    city
    state
    country
    latitude
    longitude
    instructions
    customerId
    createdAt
    updatedAt
  }
}
    `;
export type CreateUserLocationMutationFn = Apollo.MutationFunction<CreateUserLocationMutation, CreateUserLocationMutationVariables>;

/**
 * __useCreateUserLocationMutation__
 *
 * To run a mutation, you first call `useCreateUserLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserLocationMutation, { data, loading, error }] = useCreateUserLocationMutation({
 *   variables: {
 *      title: // value for 'title'
 *      streetAddress: // value for 'streetAddress'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      country: // value for 'country'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      instructions: // value for 'instructions'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useCreateUserLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserLocationMutation, CreateUserLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserLocationMutation, CreateUserLocationMutationVariables>(CreateUserLocationDocument, options);
      }
export type CreateUserLocationMutationHookResult = ReturnType<typeof useCreateUserLocationMutation>;
export type CreateUserLocationMutationResult = Apollo.MutationResult<CreateUserLocationMutation>;
export type CreateUserLocationMutationOptions = Apollo.BaseMutationOptions<CreateUserLocationMutation, CreateUserLocationMutationVariables>;
export const CreateVendorCustomerDocument = gql`
    mutation CreateVendorCustomer($vendorId: Int, $firstName: String, $lastName: String, $phoneNumber: String) {
  createVendorCustomer(
    input: {attributes: {vendorId: $vendorId, firstName: $firstName, lastName: $lastName, phoneNumber: $phoneNumber}}
  ) {
    id
    firstName
    lastName
    phoneNumber
    email
    notes
    vendorId
    updatedAt
    createdAt
  }
}
    `;
export type CreateVendorCustomerMutationFn = Apollo.MutationFunction<CreateVendorCustomerMutation, CreateVendorCustomerMutationVariables>;

/**
 * __useCreateVendorCustomerMutation__
 *
 * To run a mutation, you first call `useCreateVendorCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVendorCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVendorCustomerMutation, { data, loading, error }] = useCreateVendorCustomerMutation({
 *   variables: {
 *      vendorId: // value for 'vendorId'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useCreateVendorCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateVendorCustomerMutation, CreateVendorCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVendorCustomerMutation, CreateVendorCustomerMutationVariables>(CreateVendorCustomerDocument, options);
      }
export type CreateVendorCustomerMutationHookResult = ReturnType<typeof useCreateVendorCustomerMutation>;
export type CreateVendorCustomerMutationResult = Apollo.MutationResult<CreateVendorCustomerMutation>;
export type CreateVendorCustomerMutationOptions = Apollo.BaseMutationOptions<CreateVendorCustomerMutation, CreateVendorCustomerMutationVariables>;
export const CustomerDocument = gql`
    query Customer($id: ID!) {
  customer(id: $id) {
    id
    status
    userId
    user {
      id
      salutation
      name
      firstName
      lastName
      mobileNumber
      email
      nickname
      dob
    }
    vendorId
    vendor {
      id
      name
    }
    billingStatus
    customerNumber
    notes
    userLocations {
      id
      title
      streetAddress
      city
      state
      country
      latitude
      longitude
      instructions
      customerId
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerQuery(baseOptions: Apollo.QueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
      }
export function useCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
        }
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<typeof useCustomerLazyQuery>;
export type CustomerQueryResult = Apollo.QueryResult<CustomerQuery, CustomerQueryVariables>;
export const DailySalesDocument = gql`
    query DailySales {
  dailySales {
    date
    collectionAmount
    expenseAmount
    profit
    netProfit
  }
}
    `;

/**
 * __useDailySalesQuery__
 *
 * To run a query within a React component, call `useDailySalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailySalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailySalesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDailySalesQuery(baseOptions?: Apollo.QueryHookOptions<DailySalesQuery, DailySalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DailySalesQuery, DailySalesQueryVariables>(DailySalesDocument, options);
      }
export function useDailySalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DailySalesQuery, DailySalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DailySalesQuery, DailySalesQueryVariables>(DailySalesDocument, options);
        }
export type DailySalesQueryHookResult = ReturnType<typeof useDailySalesQuery>;
export type DailySalesLazyQueryHookResult = ReturnType<typeof useDailySalesLazyQuery>;
export type DailySalesQueryResult = Apollo.QueryResult<DailySalesQuery, DailySalesQueryVariables>;
export const DealsDocument = gql`
    query Deals {
  deals {
    id
    name
    totalPriceAmount
    totalPriceCurrency
    discountedPriceAmount
    discountedPriceCurrency
    serving
    startDate
    endDate
    status
    dealItems {
      id
      name
      quantity
      priceAmount
      priceCurrency
      discountedPriceAmount
      discountedPriceCurrency
    }
  }
}
    `;

/**
 * __useDealsQuery__
 *
 * To run a query within a React component, call `useDealsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDealsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDealsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDealsQuery(baseOptions?: Apollo.QueryHookOptions<DealsQuery, DealsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DealsQuery, DealsQueryVariables>(DealsDocument, options);
      }
export function useDealsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DealsQuery, DealsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DealsQuery, DealsQueryVariables>(DealsDocument, options);
        }
export type DealsQueryHookResult = ReturnType<typeof useDealsQuery>;
export type DealsLazyQueryHookResult = ReturnType<typeof useDealsLazyQuery>;
export type DealsQueryResult = Apollo.QueryResult<DealsQuery, DealsQueryVariables>;
export const DeleteDealDocument = gql`
    mutation DeleteDeal($id: ID!) {
  deleteDeal(input: {id: $id}) {
    id
  }
}
    `;
export type DeleteDealMutationFn = Apollo.MutationFunction<DeleteDealMutation, DeleteDealMutationVariables>;

/**
 * __useDeleteDealMutation__
 *
 * To run a mutation, you first call `useDeleteDealMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDealMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDealMutation, { data, loading, error }] = useDeleteDealMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDealMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDealMutation, DeleteDealMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDealMutation, DeleteDealMutationVariables>(DeleteDealDocument, options);
      }
export type DeleteDealMutationHookResult = ReturnType<typeof useDeleteDealMutation>;
export type DeleteDealMutationResult = Apollo.MutationResult<DeleteDealMutation>;
export type DeleteDealMutationOptions = Apollo.BaseMutationOptions<DeleteDealMutation, DeleteDealMutationVariables>;
export const DeleteUserLocationDocument = gql`
    mutation DeleteUserLocation($id: ID!) {
  deleteUserLocation(input: {id: $id}) {
    id
  }
}
    `;
export type DeleteUserLocationMutationFn = Apollo.MutationFunction<DeleteUserLocationMutation, DeleteUserLocationMutationVariables>;

/**
 * __useDeleteUserLocationMutation__
 *
 * To run a mutation, you first call `useDeleteUserLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserLocationMutation, { data, loading, error }] = useDeleteUserLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserLocationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserLocationMutation, DeleteUserLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserLocationMutation, DeleteUserLocationMutationVariables>(DeleteUserLocationDocument, options);
      }
export type DeleteUserLocationMutationHookResult = ReturnType<typeof useDeleteUserLocationMutation>;
export type DeleteUserLocationMutationResult = Apollo.MutationResult<DeleteUserLocationMutation>;
export type DeleteUserLocationMutationOptions = Apollo.BaseMutationOptions<DeleteUserLocationMutation, DeleteUserLocationMutationVariables>;
export const DiscountDocument = gql`
    query Discount($id: String!) {
  discount(id: $id) {
    uuid
    name
    code
    description
    status
    discountValue
    discountType
    maxDiscountAmount
    minOrderValue
    startDate
    expiryDate
    openingTime
    closingTime
    daysOfWeek
    priority
    audience
    applicableOn
    vendorId
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useDiscountQuery__
 *
 * To run a query within a React component, call `useDiscountQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDiscountQuery(baseOptions: Apollo.QueryHookOptions<DiscountQuery, DiscountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiscountQuery, DiscountQueryVariables>(DiscountDocument, options);
      }
export function useDiscountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiscountQuery, DiscountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiscountQuery, DiscountQueryVariables>(DiscountDocument, options);
        }
export type DiscountQueryHookResult = ReturnType<typeof useDiscountQuery>;
export type DiscountLazyQueryHookResult = ReturnType<typeof useDiscountLazyQuery>;
export type DiscountQueryResult = Apollo.QueryResult<DiscountQuery, DiscountQueryVariables>;
export const DiscountsDocument = gql`
    query Discounts($name: String, $vendorId: Int) {
  discounts(filter: {name: $name, vendorId: $vendorId}) {
    uuid
    name
    code
    description
    status
    discountValue
    discountType
    maxDiscountAmount
    minOrderValue
    startDate
    expiryDate
    openingTime
    closingTime
    daysOfWeek
    priority
    audience
    applicableOn
    vendorId
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useDiscountsQuery__
 *
 * To run a query within a React component, call `useDiscountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useDiscountsQuery(baseOptions?: Apollo.QueryHookOptions<DiscountsQuery, DiscountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiscountsQuery, DiscountsQueryVariables>(DiscountsDocument, options);
      }
export function useDiscountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiscountsQuery, DiscountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiscountsQuery, DiscountsQueryVariables>(DiscountsDocument, options);
        }
export type DiscountsQueryHookResult = ReturnType<typeof useDiscountsQuery>;
export type DiscountsLazyQueryHookResult = ReturnType<typeof useDiscountsLazyQuery>;
export type DiscountsQueryResult = Apollo.QueryResult<DiscountsQuery, DiscountsQueryVariables>;
export const ExpensesByItemDocument = gql`
    query ExpensesByItem($startDate: String, $endDate: String, $name: String!) {
  expensesByItem(startDate: $startDate, endDate: $endDate, name: $name) {
    id
    name
    rateAmount
    rate
    costAmount
    cost
    quantity
    unit
    expenseDate
    formattedExpenseDate
    quantityWithUnit
  }
  totalExpensesByItem(startDate: $startDate, endDate: $endDate, name: $name) {
    name
    total
    startDate
    endDate
  }
}
    `;

/**
 * __useExpensesByItemQuery__
 *
 * To run a query within a React component, call `useExpensesByItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpensesByItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpensesByItemQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useExpensesByItemQuery(baseOptions: Apollo.QueryHookOptions<ExpensesByItemQuery, ExpensesByItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpensesByItemQuery, ExpensesByItemQueryVariables>(ExpensesByItemDocument, options);
      }
export function useExpensesByItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpensesByItemQuery, ExpensesByItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpensesByItemQuery, ExpensesByItemQueryVariables>(ExpensesByItemDocument, options);
        }
export type ExpensesByItemQueryHookResult = ReturnType<typeof useExpensesByItemQuery>;
export type ExpensesByItemLazyQueryHookResult = ReturnType<typeof useExpensesByItemLazyQuery>;
export type ExpensesByItemQueryResult = Apollo.QueryResult<ExpensesByItemQuery, ExpensesByItemQueryVariables>;
export const ExpensesDocument = gql`
    query Expenses($startDate: String!, $endDate: String!) {
  expenses(filter: {startDate: $startDate, endDate: $endDate}) {
    id
    name
    rateAmount
    rate
    costAmount
    cost
    quantity
    unit
    expenseDate
    formattedExpenseDate
    quantityWithUnit
  }
  totalExpenses(startDate: $startDate, endDate: $endDate) {
    total
    startDate
    endDate
  }
}
    `;

/**
 * __useExpensesQuery__
 *
 * To run a query within a React component, call `useExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExpensesQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useExpensesQuery(baseOptions: Apollo.QueryHookOptions<ExpensesQuery, ExpensesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpensesQuery, ExpensesQueryVariables>(ExpensesDocument, options);
      }
export function useExpensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpensesQuery, ExpensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpensesQuery, ExpensesQueryVariables>(ExpensesDocument, options);
        }
export type ExpensesQueryHookResult = ReturnType<typeof useExpensesQuery>;
export type ExpensesLazyQueryHookResult = ReturnType<typeof useExpensesLazyQuery>;
export type ExpensesQueryResult = Apollo.QueryResult<ExpensesQuery, ExpensesQueryVariables>;
export const FoodCategoriesDocument = gql`
    query FoodCategories {
  foodCategories {
    id
    name
    foodItems {
      id
      name
      description
      priceAmount
      priceCurrency
      foodCategoryId
      foodCategory {
        name
      }
      menuId
    }
  }
}
    `;

/**
 * __useFoodCategoriesQuery__
 *
 * To run a query within a React component, call `useFoodCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFoodCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<FoodCategoriesQuery, FoodCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FoodCategoriesQuery, FoodCategoriesQueryVariables>(FoodCategoriesDocument, options);
      }
export function useFoodCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoodCategoriesQuery, FoodCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FoodCategoriesQuery, FoodCategoriesQueryVariables>(FoodCategoriesDocument, options);
        }
export type FoodCategoriesQueryHookResult = ReturnType<typeof useFoodCategoriesQuery>;
export type FoodCategoriesLazyQueryHookResult = ReturnType<typeof useFoodCategoriesLazyQuery>;
export type FoodCategoriesQueryResult = Apollo.QueryResult<FoodCategoriesQuery, FoodCategoriesQueryVariables>;
export const FoodItemsDocument = gql`
    query FoodItems {
  foodItems {
    id
    name
    description
    slug
    priceAmount
    priceCurrency
    foodCategory {
      name
    }
  }
}
    `;

/**
 * __useFoodItemsQuery__
 *
 * To run a query within a React component, call `useFoodItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFoodItemsQuery(baseOptions?: Apollo.QueryHookOptions<FoodItemsQuery, FoodItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FoodItemsQuery, FoodItemsQueryVariables>(FoodItemsDocument, options);
      }
export function useFoodItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoodItemsQuery, FoodItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FoodItemsQuery, FoodItemsQueryVariables>(FoodItemsDocument, options);
        }
export type FoodItemsQueryHookResult = ReturnType<typeof useFoodItemsQuery>;
export type FoodItemsLazyQueryHookResult = ReturnType<typeof useFoodItemsLazyQuery>;
export type FoodItemsQueryResult = Apollo.QueryResult<FoodItemsQuery, FoodItemsQueryVariables>;
export const MenuDocument = gql`
    query Menu($id: ID!) {
  menu(id: $id) {
    id
    name
    slug
    vendor {
      id
      name
    }
  }
  foodCategories {
    id
    name
    foodItems {
      id
      name
      description
      priceAmount
      priceCurrency
      foodCategoryId
      menuId
    }
  }
}
    `;

/**
 * __useMenuQuery__
 *
 * To run a query within a React component, call `useMenuQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMenuQuery(baseOptions: Apollo.QueryHookOptions<MenuQuery, MenuQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MenuQuery, MenuQueryVariables>(MenuDocument, options);
      }
export function useMenuLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MenuQuery, MenuQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MenuQuery, MenuQueryVariables>(MenuDocument, options);
        }
export type MenuQueryHookResult = ReturnType<typeof useMenuQuery>;
export type MenuLazyQueryHookResult = ReturnType<typeof useMenuLazyQuery>;
export type MenuQueryResult = Apollo.QueryResult<MenuQuery, MenuQueryVariables>;
export const OrderDocument = gql`
    query Order($id: ID!) {
  order(id: $id) {
    id
    orderNumber
    orderType
    humanizedOrderType
    status
    humanizedStatus
    totalPriceAmount
    totalPrice
    deliveryFeeAmount
    placedFrom
    itemsCount
    vendorCustomerId
    vendorId
    receivedAt
    acceptedAt
    pickedAt
    deliveredAt
    canceledAt
    createdAt
    updatedAt
    discountAmount
    discountCode
    discountedPriceAmount
    gstAmount
    netTotalAmount
    vendor {
      id
      name
      uuid
    }
    vendorCustomer {
      id
      firstName
      lastName
      email
      notes
      phoneNumber
    }
    orderItems {
      uuid
      itemName
      itemQuantity
      priceAmount
      priceCurrency
      rateAmount
      rateCurrency
      parentId
      orderId
    }
  }
}
    `;

/**
 * __useOrderQuery__
 *
 * To run a query within a React component, call `useOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderQuery(baseOptions: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
      }
export function useOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
        }
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = Apollo.QueryResult<OrderQuery, OrderQueryVariables>;
export const OrdersDocument = gql`
    query Orders($page: Int!, $pageSize: Int!, $fromDate: String, $toDate: String) {
  orders(
    paginate: {page: $page, pageSize: $pageSize}
    filter: {fromDate: $fromDate, toDate: $toDate}
  ) {
    id
    orderNumber
    orderType
    humanizedOrderType
    status
    humanizedStatus
    totalPriceAmount
    totalPrice
    deliveryFeeAmount
    placedFrom
    itemsCount
    vendorCustomerId
    vendorId
    receivedAt
    acceptedAt
    pickedAt
    deliveredAt
    canceledAt
    createdAt
    updatedAt
    discountAmount
    discountCode
    discountedPriceAmount
    gstAmount
    netTotalAmount
    vendor {
      id
      name
      uuid
    }
    vendorCustomer {
      id
      firstName
      lastName
      email
      notes
      phoneNumber
    }
    orderItems {
      uuid
      itemName
      itemQuantity
      priceAmount
      priceCurrency
      rateAmount
      rateCurrency
      parentId
      orderId
    }
  }
  ordersSummary(startDate: $fromDate, endDate: $toDate) {
    totalOrders
    totalCollection
    totalItems
    itemsSold {
      itemName
      itemQuantity
      totalPriceAmount
    }
  }
  ordersTotalPrice(startDate: $fromDate, endDate: $toDate) {
    total
    startDate
    endDate
  }
}
    `;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useOrdersQuery(baseOptions: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
      }
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const BrandListDocument = gql`
    query BrandList($name: String) {
  brands(filter: {name: $name}) {
    id
    name
    slug
  }
}
    `;

/**
 * __useBrandListQuery__
 *
 * To run a query within a React component, call `useBrandListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandListQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useBrandListQuery(baseOptions?: Apollo.QueryHookOptions<BrandListQuery, BrandListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandListQuery, BrandListQueryVariables>(BrandListDocument, options);
      }
export function useBrandListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandListQuery, BrandListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandListQuery, BrandListQueryVariables>(BrandListDocument, options);
        }
export type BrandListQueryHookResult = ReturnType<typeof useBrandListQuery>;
export type BrandListLazyQueryHookResult = ReturnType<typeof useBrandListLazyQuery>;
export type BrandListQueryResult = Apollo.QueryResult<BrandListQuery, BrandListQueryVariables>;
export const DailyStatsDocument = gql`
    query dailyStats {
  todayOrdersCount {
    total
  }
  todayExpensesTotal {
    total
  }
}
    `;

/**
 * __useDailyStatsQuery__
 *
 * To run a query within a React component, call `useDailyStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDailyStatsQuery(baseOptions?: Apollo.QueryHookOptions<DailyStatsQuery, DailyStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DailyStatsQuery, DailyStatsQueryVariables>(DailyStatsDocument, options);
      }
export function useDailyStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DailyStatsQuery, DailyStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DailyStatsQuery, DailyStatsQueryVariables>(DailyStatsDocument, options);
        }
export type DailyStatsQueryHookResult = ReturnType<typeof useDailyStatsQuery>;
export type DailyStatsLazyQueryHookResult = ReturnType<typeof useDailyStatsLazyQuery>;
export type DailyStatsQueryResult = Apollo.QueryResult<DailyStatsQuery, DailyStatsQueryVariables>;
export const UpdateCustomerDocument = gql`
    mutation UpdateCustomer($id: ID!, $firstName: String, $lastName: String, $mobileNumber: String, $nickname: String, $email: String, $dob: String, $salutation: String, $billingStatus: String, $notes: String) {
  updateCustomer(
    input: {id: $id, attributes: {firstName: $firstName, lastName: $lastName, mobileNumber: $mobileNumber, nickname: $nickname, email: $email, dob: $dob, salutation: $salutation, billingStatus: $billingStatus, notes: $notes}}
  ) {
    id
    status
    userId
    user {
      id
      salutation
      name
      firstName
      lastName
      mobileNumber
      email
      nickname
      dob
    }
    vendorId
    vendor {
      id
      name
    }
    billingStatus
    customerNumber
    notes
  }
}
    `;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      mobileNumber: // value for 'mobileNumber'
 *      nickname: // value for 'nickname'
 *      email: // value for 'email'
 *      dob: // value for 'dob'
 *      salutation: // value for 'salutation'
 *      billingStatus: // value for 'billingStatus'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, options);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const UpdateDiscountDocument = gql`
    mutation UpdateDiscount($id: String!, $name: String, $code: String, $description: String, $status: String, $discountValue: Float, $discountType: String, $maxDiscountAmount: Float, $minOrderValue: Float, $startDate: ISO8601Date, $expiryDate: ISO8601Date, $openingTime: String, $closingTime: String, $daysOfWeek: String, $priority: Int, $audience: String, $applicableOn: String, $vendorId: Int!) {
  updateDiscount(
    input: {id: $id, attributes: {name: $name, code: $code, description: $description, status: $status, discountValue: $discountValue, discountType: $discountType, maxDiscountAmount: $maxDiscountAmount, minOrderValue: $minOrderValue, startDate: $startDate, expiryDate: $expiryDate, openingTime: $openingTime, closingTime: $closingTime, daysOfWeek: $daysOfWeek, priority: $priority, audience: $audience, applicableOn: $applicableOn, vendorId: $vendorId}}
  ) {
    uuid
    name
    code
    description
    status
    discountValue
    discountType
    maxDiscountAmount
    minOrderValue
    startDate
    expiryDate
    openingTime
    closingTime
    daysOfWeek
    priority
    audience
    applicableOn
    vendorId
    createdAt
    updatedAt
  }
}
    `;
export type UpdateDiscountMutationFn = Apollo.MutationFunction<UpdateDiscountMutation, UpdateDiscountMutationVariables>;

/**
 * __useUpdateDiscountMutation__
 *
 * To run a mutation, you first call `useUpdateDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDiscountMutation, { data, loading, error }] = useUpdateDiscountMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      description: // value for 'description'
 *      status: // value for 'status'
 *      discountValue: // value for 'discountValue'
 *      discountType: // value for 'discountType'
 *      maxDiscountAmount: // value for 'maxDiscountAmount'
 *      minOrderValue: // value for 'minOrderValue'
 *      startDate: // value for 'startDate'
 *      expiryDate: // value for 'expiryDate'
 *      openingTime: // value for 'openingTime'
 *      closingTime: // value for 'closingTime'
 *      daysOfWeek: // value for 'daysOfWeek'
 *      priority: // value for 'priority'
 *      audience: // value for 'audience'
 *      applicableOn: // value for 'applicableOn'
 *      vendorId: // value for 'vendorId'
 *   },
 * });
 */
export function useUpdateDiscountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDiscountMutation, UpdateDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDiscountMutation, UpdateDiscountMutationVariables>(UpdateDiscountDocument, options);
      }
export type UpdateDiscountMutationHookResult = ReturnType<typeof useUpdateDiscountMutation>;
export type UpdateDiscountMutationResult = Apollo.MutationResult<UpdateDiscountMutation>;
export type UpdateDiscountMutationOptions = Apollo.BaseMutationOptions<UpdateDiscountMutation, UpdateDiscountMutationVariables>;
export const UpdateExpenseDocument = gql`
    mutation UpdateExpense($id: ID!, $name: String, $rateAmount: Float, $costAmount: Float, $quantity: Float, $unit: String, $expenseDate: String) {
  updateExpense(
    input: {id: $id, attributes: {name: $name, rateAmount: $rateAmount, rateCurrency: "PKR", costAmount: $costAmount, costCurrency: "PKR", quantity: $quantity, unit: $unit, expenseDate: $expenseDate}}
  ) {
    id
    name
    rateAmount
    rate
    costAmount
    cost
    quantity
    unit
    expenseDate
    formattedExpenseDate
    quantityWithUnit
  }
}
    `;
export type UpdateExpenseMutationFn = Apollo.MutationFunction<UpdateExpenseMutation, UpdateExpenseMutationVariables>;

/**
 * __useUpdateExpenseMutation__
 *
 * To run a mutation, you first call `useUpdateExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExpenseMutation, { data, loading, error }] = useUpdateExpenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      rateAmount: // value for 'rateAmount'
 *      costAmount: // value for 'costAmount'
 *      quantity: // value for 'quantity'
 *      unit: // value for 'unit'
 *      expenseDate: // value for 'expenseDate'
 *   },
 * });
 */
export function useUpdateExpenseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExpenseMutation, UpdateExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateExpenseMutation, UpdateExpenseMutationVariables>(UpdateExpenseDocument, options);
      }
export type UpdateExpenseMutationHookResult = ReturnType<typeof useUpdateExpenseMutation>;
export type UpdateExpenseMutationResult = Apollo.MutationResult<UpdateExpenseMutation>;
export type UpdateExpenseMutationOptions = Apollo.BaseMutationOptions<UpdateExpenseMutation, UpdateExpenseMutationVariables>;
export const UpdateFoodItemDocument = gql`
    mutation UpdateFoodItem($id: ID!, $name: String!, $description: String, $priceAmount: Float!, $foodCategoryId: Int!, $menuId: Int!) {
  updateFoodItem(
    input: {id: $id, attributes: {name: $name, description: $description, priceAmount: $priceAmount, foodCategoryId: $foodCategoryId, menuId: $menuId}}
  ) {
    id
    name
    description
    slug
    priceAmount
    priceCurrency
    foodCategory {
      name
    }
  }
}
    `;
export type UpdateFoodItemMutationFn = Apollo.MutationFunction<UpdateFoodItemMutation, UpdateFoodItemMutationVariables>;

/**
 * __useUpdateFoodItemMutation__
 *
 * To run a mutation, you first call `useUpdateFoodItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFoodItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFoodItemMutation, { data, loading, error }] = useUpdateFoodItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      priceAmount: // value for 'priceAmount'
 *      foodCategoryId: // value for 'foodCategoryId'
 *      menuId: // value for 'menuId'
 *   },
 * });
 */
export function useUpdateFoodItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFoodItemMutation, UpdateFoodItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFoodItemMutation, UpdateFoodItemMutationVariables>(UpdateFoodItemDocument, options);
      }
export type UpdateFoodItemMutationHookResult = ReturnType<typeof useUpdateFoodItemMutation>;
export type UpdateFoodItemMutationResult = Apollo.MutationResult<UpdateFoodItemMutation>;
export type UpdateFoodItemMutationOptions = Apollo.BaseMutationOptions<UpdateFoodItemMutation, UpdateFoodItemMutationVariables>;
export const UpdateOrderDocument = gql`
    mutation UpdateOrder($id: ID!, $vendorCustomerId: Int, $receivedAt: String, $orderType: String, $status: String, $deliveryFeeAmount: Float, $discountAmount: Float, $discountCode: String, $discountedPriceAmount: Float, $orderItems: [OrderItemAttributes!]) {
  updateOrder(
    input: {id: $id, attributes: {orderItems: $orderItems, vendorCustomerId: $vendorCustomerId, receivedAt: $receivedAt, orderType: $orderType, status: $status, deliveryFeeAmount: $deliveryFeeAmount, discountAmount: $discountAmount, discountCode: $discountCode, discountedPriceAmount: $discountedPriceAmount}}
  ) {
    id
    orderNumber
    orderType
    humanizedOrderType
    status
    humanizedStatus
    totalPriceAmount
    totalPrice
    deliveryFeeAmount
    placedFrom
    itemsCount
    vendorCustomerId
    vendorId
    receivedAt
    acceptedAt
    pickedAt
    deliveredAt
    canceledAt
    createdAt
    updatedAt
    vendor {
      id
      name
      uuid
    }
    vendorCustomer {
      id
      firstName
      lastName
      email
      notes
      phoneNumber
    }
    orderItems {
      uuid
      itemName
      itemQuantity
      priceAmount
      priceCurrency
      rateAmount
      rateCurrency
      parentId
      orderId
    }
  }
}
    `;
export type UpdateOrderMutationFn = Apollo.MutationFunction<UpdateOrderMutation, UpdateOrderMutationVariables>;

/**
 * __useUpdateOrderMutation__
 *
 * To run a mutation, you first call `useUpdateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrderMutation, { data, loading, error }] = useUpdateOrderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      vendorCustomerId: // value for 'vendorCustomerId'
 *      receivedAt: // value for 'receivedAt'
 *      orderType: // value for 'orderType'
 *      status: // value for 'status'
 *      deliveryFeeAmount: // value for 'deliveryFeeAmount'
 *      discountAmount: // value for 'discountAmount'
 *      discountCode: // value for 'discountCode'
 *      discountedPriceAmount: // value for 'discountedPriceAmount'
 *      orderItems: // value for 'orderItems'
 *   },
 * });
 */
export function useUpdateOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderMutation, UpdateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(UpdateOrderDocument, options);
      }
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = Apollo.MutationResult<UpdateOrderMutation>;
export type UpdateOrderMutationOptions = Apollo.BaseMutationOptions<UpdateOrderMutation, UpdateOrderMutationVariables>;
export const UpdateProductCategoryDocument = gql`
    mutation UpdateProductCategory($id: ID!, $name: String, $description: String) {
  updateProductCategory(
    input: {id: $id, attributes: {name: $name, description: $description}}
  ) {
    id
    name
    description
  }
}
    `;
export type UpdateProductCategoryMutationFn = Apollo.MutationFunction<UpdateProductCategoryMutation, UpdateProductCategoryMutationVariables>;

/**
 * __useUpdateProductCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateProductCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductCategoryMutation, { data, loading, error }] = useUpdateProductCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateProductCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductCategoryMutation, UpdateProductCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductCategoryMutation, UpdateProductCategoryMutationVariables>(UpdateProductCategoryDocument, options);
      }
export type UpdateProductCategoryMutationHookResult = ReturnType<typeof useUpdateProductCategoryMutation>;
export type UpdateProductCategoryMutationResult = Apollo.MutationResult<UpdateProductCategoryMutation>;
export type UpdateProductCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateProductCategoryMutation, UpdateProductCategoryMutationVariables>;
export const UpdateProductDocument = gql`
    mutation UpdateProduct($id: ID!, $name: String, $description: String, $supplierId: Int, $brandId: Int, $productCategoryId: Int) {
  updateProduct(
    input: {id: $id, attributes: {name: $name, description: $description, supplierId: $supplierId, brandId: $brandId, productCategoryId: $productCategoryId}}
  ) {
    id
    name
    description
    supplierId
    supplier {
      id
      name
      email
      phoneNumber
    }
    productCategoryId
    productCategory {
      id
      name
    }
    brandId
    brand {
      id
      name
    }
  }
}
    `;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      supplierId: // value for 'supplierId'
 *      brandId: // value for 'brandId'
 *      productCategoryId: // value for 'productCategoryId'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const UpdateStockItemDocument = gql`
    mutation UpdateStockItem($id: ID!, $quantity: Float, $unit: String, $unitPrice: Float, $totalPrice: Float, $minQuantityRequired: String, $status: String, $lastPurchasedDate: String, $stockId: Int) {
  updateStockItem(
    input: {id: $id, attributes: {quantity: $quantity, unit: $unit, unitPrice: $unitPrice, totalPrice: $totalPrice, minQuantityRequired: $minQuantityRequired, status: $status, lastPurchasedDate: $lastPurchasedDate, stockId: $stockId}}
  ) {
    id
    productName
    quantity
    unit
    unitPrice
    totalPrice
    minQuantityRequired
    status
    lastPurchasedDate
    stockId
    stock {
      id
      name
    }
  }
}
    `;
export type UpdateStockItemMutationFn = Apollo.MutationFunction<UpdateStockItemMutation, UpdateStockItemMutationVariables>;

/**
 * __useUpdateStockItemMutation__
 *
 * To run a mutation, you first call `useUpdateStockItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStockItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStockItemMutation, { data, loading, error }] = useUpdateStockItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      quantity: // value for 'quantity'
 *      unit: // value for 'unit'
 *      unitPrice: // value for 'unitPrice'
 *      totalPrice: // value for 'totalPrice'
 *      minQuantityRequired: // value for 'minQuantityRequired'
 *      status: // value for 'status'
 *      lastPurchasedDate: // value for 'lastPurchasedDate'
 *      stockId: // value for 'stockId'
 *   },
 * });
 */
export function useUpdateStockItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStockItemMutation, UpdateStockItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStockItemMutation, UpdateStockItemMutationVariables>(UpdateStockItemDocument, options);
      }
export type UpdateStockItemMutationHookResult = ReturnType<typeof useUpdateStockItemMutation>;
export type UpdateStockItemMutationResult = Apollo.MutationResult<UpdateStockItemMutation>;
export type UpdateStockItemMutationOptions = Apollo.BaseMutationOptions<UpdateStockItemMutation, UpdateStockItemMutationVariables>;
export const UpdateSupplierDocument = gql`
    mutation UpdateSupplier($id: ID!, $name: String, $address: String, $phoneNumber: String, $status: String, $email: String) {
  updateSupplier(
    input: {id: $id, attributes: {name: $name, address: $address, phoneNumber: $phoneNumber, status: $status, email: $email}}
  ) {
    id
    name
    address
    phoneNumber
    status
    email
  }
}
    `;
export type UpdateSupplierMutationFn = Apollo.MutationFunction<UpdateSupplierMutation, UpdateSupplierMutationVariables>;

/**
 * __useUpdateSupplierMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierMutation, { data, loading, error }] = useUpdateSupplierMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      address: // value for 'address'
 *      phoneNumber: // value for 'phoneNumber'
 *      status: // value for 'status'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUpdateSupplierMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSupplierMutation, UpdateSupplierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSupplierMutation, UpdateSupplierMutationVariables>(UpdateSupplierDocument, options);
      }
export type UpdateSupplierMutationHookResult = ReturnType<typeof useUpdateSupplierMutation>;
export type UpdateSupplierMutationResult = Apollo.MutationResult<UpdateSupplierMutation>;
export type UpdateSupplierMutationOptions = Apollo.BaseMutationOptions<UpdateSupplierMutation, UpdateSupplierMutationVariables>;
export const UpdateUserLocationDocument = gql`
    mutation UpdateUserLocation($id: ID!, $title: String, $streetAddress: String, $city: String, $state: String, $country: String, $latitude: String, $longitude: String, $instructions: String, $customerId: Int) {
  updateUserLocation(
    input: {id: $id, attributes: {title: $title, streetAddress: $streetAddress, city: $city, state: $state, country: $country, latitude: $latitude, longitude: $longitude, instructions: $instructions, customerId: $customerId}}
  ) {
    id
    title
    streetAddress
    city
    state
    country
    latitude
    longitude
    instructions
    customerId
    createdAt
    updatedAt
  }
}
    `;
export type UpdateUserLocationMutationFn = Apollo.MutationFunction<UpdateUserLocationMutation, UpdateUserLocationMutationVariables>;

/**
 * __useUpdateUserLocationMutation__
 *
 * To run a mutation, you first call `useUpdateUserLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserLocationMutation, { data, loading, error }] = useUpdateUserLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      streetAddress: // value for 'streetAddress'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      country: // value for 'country'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *      instructions: // value for 'instructions'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useUpdateUserLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserLocationMutation, UpdateUserLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserLocationMutation, UpdateUserLocationMutationVariables>(UpdateUserLocationDocument, options);
      }
export type UpdateUserLocationMutationHookResult = ReturnType<typeof useUpdateUserLocationMutation>;
export type UpdateUserLocationMutationResult = Apollo.MutationResult<UpdateUserLocationMutation>;
export type UpdateUserLocationMutationOptions = Apollo.BaseMutationOptions<UpdateUserLocationMutation, UpdateUserLocationMutationVariables>;
export const UpdateVendorCustomerDocument = gql`
    mutation UpdateVendorCustomer($id: ID!, $firstName: String, $lastName: String, $phoneNumber: String, $email: String, $notes: String) {
  updateVendorCustomer(
    input: {id: $id, attributes: {firstName: $firstName, lastName: $lastName, phoneNumber: $phoneNumber, email: $email, notes: $notes}}
  ) {
    id
    firstName
    lastName
    phoneNumber
    email
    notes
    vendorId
    createdAt
    updatedAt
  }
}
    `;
export type UpdateVendorCustomerMutationFn = Apollo.MutationFunction<UpdateVendorCustomerMutation, UpdateVendorCustomerMutationVariables>;

/**
 * __useUpdateVendorCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateVendorCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVendorCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVendorCustomerMutation, { data, loading, error }] = useUpdateVendorCustomerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      phoneNumber: // value for 'phoneNumber'
 *      email: // value for 'email'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useUpdateVendorCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVendorCustomerMutation, UpdateVendorCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVendorCustomerMutation, UpdateVendorCustomerMutationVariables>(UpdateVendorCustomerDocument, options);
      }
export type UpdateVendorCustomerMutationHookResult = ReturnType<typeof useUpdateVendorCustomerMutation>;
export type UpdateVendorCustomerMutationResult = Apollo.MutationResult<UpdateVendorCustomerMutation>;
export type UpdateVendorCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateVendorCustomerMutation, UpdateVendorCustomerMutationVariables>;
export const UserLocationDocument = gql`
    query UserLocation($id: ID!) {
  userLocation(id: $id) {
    id
    title
    streetAddress
    city
    state
    country
    latitude
    longitude
    instructions
    customerId
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useUserLocationQuery__
 *
 * To run a query within a React component, call `useUserLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserLocationQuery(baseOptions: Apollo.QueryHookOptions<UserLocationQuery, UserLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserLocationQuery, UserLocationQueryVariables>(UserLocationDocument, options);
      }
export function useUserLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserLocationQuery, UserLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserLocationQuery, UserLocationQueryVariables>(UserLocationDocument, options);
        }
export type UserLocationQueryHookResult = ReturnType<typeof useUserLocationQuery>;
export type UserLocationLazyQueryHookResult = ReturnType<typeof useUserLocationLazyQuery>;
export type UserLocationQueryResult = Apollo.QueryResult<UserLocationQuery, UserLocationQueryVariables>;
export const UserLocationsDocument = gql`
    query UserLocations($customerId: Int!) {
  userLocations(filter: {customerId: $customerId}) {
    id
    title
    streetAddress
    city
    state
    country
    latitude
    longitude
    instructions
    customerId
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useUserLocationsQuery__
 *
 * To run a query within a React component, call `useUserLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLocationsQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useUserLocationsQuery(baseOptions: Apollo.QueryHookOptions<UserLocationsQuery, UserLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserLocationsQuery, UserLocationsQueryVariables>(UserLocationsDocument, options);
      }
export function useUserLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserLocationsQuery, UserLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserLocationsQuery, UserLocationsQueryVariables>(UserLocationsDocument, options);
        }
export type UserLocationsQueryHookResult = ReturnType<typeof useUserLocationsQuery>;
export type UserLocationsLazyQueryHookResult = ReturnType<typeof useUserLocationsLazyQuery>;
export type UserLocationsQueryResult = Apollo.QueryResult<UserLocationsQuery, UserLocationsQueryVariables>;
export const VendorCustomersDocument = gql`
    query VendorCustomers {
  vendorCustomers {
    id
    firstName
    lastName
    phoneNumber
    email
    notes
    vendorId
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useVendorCustomersQuery__
 *
 * To run a query within a React component, call `useVendorCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorCustomersQuery({
 *   variables: {
 *   },
 * });
 */
export function useVendorCustomersQuery(baseOptions?: Apollo.QueryHookOptions<VendorCustomersQuery, VendorCustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VendorCustomersQuery, VendorCustomersQueryVariables>(VendorCustomersDocument, options);
      }
export function useVendorCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VendorCustomersQuery, VendorCustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VendorCustomersQuery, VendorCustomersQueryVariables>(VendorCustomersDocument, options);
        }
export type VendorCustomersQueryHookResult = ReturnType<typeof useVendorCustomersQuery>;
export type VendorCustomersLazyQueryHookResult = ReturnType<typeof useVendorCustomersLazyQuery>;
export type VendorCustomersQueryResult = Apollo.QueryResult<VendorCustomersQuery, VendorCustomersQueryVariables>;
export const VendorsDocument = gql`
    query Vendors {
  vendors {
    id
    name
    slug
    clientType
    address
    state
    country
    phone
    email
    website
    parentId
  }
}
    `;

/**
 * __useVendorsQuery__
 *
 * To run a query within a React component, call `useVendorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVendorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVendorsQuery({
 *   variables: {
 *   },
 * });
 */
export function useVendorsQuery(baseOptions?: Apollo.QueryHookOptions<VendorsQuery, VendorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VendorsQuery, VendorsQueryVariables>(VendorsDocument, options);
      }
export function useVendorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VendorsQuery, VendorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VendorsQuery, VendorsQueryVariables>(VendorsDocument, options);
        }
export type VendorsQueryHookResult = ReturnType<typeof useVendorsQuery>;
export type VendorsLazyQueryHookResult = ReturnType<typeof useVendorsLazyQuery>;
export type VendorsQueryResult = Apollo.QueryResult<VendorsQuery, VendorsQueryVariables>;