import React from 'react';
import { Typography} from 'antd';
const { Title } = Typography;
interface Props {
  title: string;
}

const PageTitle: React.FC<Props> = ({ title }) => {
  return (
    <Title level={3} style={{margin: 0}}>{title}</Title>
  );
};

export default PageTitle;
