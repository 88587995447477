import React from 'react';
import ReactDOM from 'react-dom/client';
import isEmpty from 'lodash.isempty';
import {
  ApolloProvider,
  HttpLink,
  ApolloClient,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import './index.css';
import 'antd/dist/antd.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BreakpointProvider } from './hooks/breakpoints';
import { queries } from './utils/constants';

const graphQLink = new HttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  let userSession = {
    accessToken: '',
    expiry: '',
    client: '',
    uid: '',
  };
  const userSessionString = localStorage.getItem('userSession');
  if (userSessionString && !isEmpty(userSessionString))
    userSession = JSON.parse(userSessionString);

  return {
    headers: {
      ...headers,
      'access-token': userSession.accessToken || '',
      client: userSession.client || '',
      uid: userSession.uid || '',
      'token-type': 'Bearer',
    },
  };
});

const cache = new InMemoryCache();
const apolloClient = new ApolloClient({
  link: authLink.concat(graphQLink),
  cache,
  credentials: 'include',
  resolvers: {},
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <BreakpointProvider queries={queries}>
        <App />
      </BreakpointProvider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
