import React, { useState, useEffect } from 'react';
import {
  useProductCategoriesLazyQuery,
  ProductCategory,
  useCreateProductCategoryMutation,
} from '../../generated/graphql';
import Loading from '../../components/Loading';
import Error from '../../components/Error';
import style from '../StockItems/StockItems.module.scss';
import styles from './ProductCategories.module.scss';
import { Layout, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import PageTitle from '../../components/PageHeading';
import isEmpty from 'lodash.isempty';
import ProductCategoriesList from '../../components/ProductCategoriesList';
import NewProductCategoryForm from '../../components/NewProductCategoryForm';
import SearchInput from '../../components/SearchInput';
import EmptyPlaceholder from '../../components/EmptyPlaceholder';

const ProductCategories = () => {
  const { Content } = Layout;
  const [visible, setVisible] = useState(false);
  const [productCategoriesQuery, { data, error, loading }] =
    useProductCategoriesLazyQuery();
  const [productCategories, setProductCategories] = useState<
    Partial<ProductCategory>[]
  >([]);
  const [
    createProductCategoryMutation,
    { data: data1, loading: loading1, error: error1 },
  ] = useCreateProductCategoryMutation();

  useEffect(() => {
    productCategoriesQuery();
  }, [productCategoriesQuery]);

  useEffect(() => {
    const productCategories = data ? data.productCategories : [];
    setProductCategories(productCategories);
  }, [data]);

  const onCreate = (values: any) => {
    console.log('Received values of form: ', values);
    createProductCategoryMutation({
      variables: {
        ...values,
      },
    }).then((response) => {
      if (response.data?.createProductCategory) {
        setProductCategories(
          productCategories?.concat(response.data?.createProductCategory)
        );
      }
    });
    setVisible(false);
  };

  const updateProductCategory = (productCategory: Partial<ProductCategory>) => {
    if (productCategories) {
      const productCategoryList: Partial<ProductCategory>[] = [
        ...productCategories,
      ];
      const index = productCategoryList.findIndex(
        (c) => c.id === productCategory.id
      );
      productCategoryList.splice(index, 1, productCategory);
      setProductCategories(productCategoryList);
    }
  };

  const filterItems = (searchItem: string) => {
    if (searchItem !== '') {
      productCategoriesQuery({
        variables: { name: searchItem },
      });
    } else if (searchItem === '') {
      productCategoriesQuery();
      const productCategories = data ? data.productCategories : [];
      setProductCategories(productCategories);
    }
  };

  return (
    <>
      <Layout className={styles.productCategories}>
        <Content className={styles.wrapper}>
          <div className={style.PageHeading}>
            <div className={style.header}>
              <PageTitle title='Product Categories' />
              <Button
                className={style.btn}
                icon={<PlusOutlined />}
                onClick={() => {
                  setVisible(true);
                }}
              />
            </div>
            <SearchInput
              filterItems={filterItems}
              placeholder='Search by name'
            />
          </div>
          {error && <Error />}
          <Loading loading={loading} />
          {!loading && !error && (
            <>
              {isEmpty(productCategories) && <EmptyPlaceholder />}

              {!isEmpty(productCategories) && (
                <ProductCategoriesList
                  productCategories={productCategories!}
                  updateProductCategory={updateProductCategory}
                />
              )}
            </>
          )}
        </Content>
      </Layout>
      <NewProductCategoryForm
        visible={visible}
        onCreate={onCreate}
        onCancel={() => {
          setVisible(false);
        }}
      />
    </>
  );
};

export default ProductCategories;
