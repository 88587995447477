import { Spin } from 'antd';
import React from 'react';

interface Props {
  loading: boolean;
}

const Loading: React.FC<Props> = ({ loading }) => (
  <>
    {loading && (
      <div
        style={{
          minHeight: 200,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin />
      </div>
    )}
  </>
);

export default Loading;
