import React from 'react';
import { Typography } from 'antd';
import styles from './DataRow.module.scss';
const { Text } = Typography;

interface Props {
  label: string;
  value: string | number;
}

const DataRow: React.FC<Props> = ({ label, value }) => {
  return (
    <div className={styles.block}>
      <div className={styles.label}>
        <Text>{label}</Text>
      </div>
      <div className='value'>
        <Text>{value}</Text>
      </div>
    </div>
  );
};

export default DataRow;
