import React, { useEffect, useState } from 'react';
import { Button, Drawer, Form, Input, Space, message } from 'antd';
import { useBreakpoint } from './../../hooks/breakpoints';
import {
  useUpdateUserLocationMutation,
  useCreateUserLocationMutation,
} from '../../generated/graphql';
import { UserLocationType } from '../../types';
import { useParams } from 'react-router-dom';
const { TextArea } = Input;

interface Props {
  visible: boolean;
  onCancel: () => void;
  userLocation?: UserLocationType | null;
  customerId: string | undefined;
  newLocation: (location: UserLocationType) => void;
}

const UserLocationForm: React.FC<Props> = ({
  onCancel,
  visible,
  userLocation,
  customerId,
  newLocation,
}) => {
  const Params = useParams();
  const [form] = Form.useForm();
  const [drawerWidth, setDrawerWidth] = useState<string>('500px');
  const breakpoints: any = useBreakpoint();
  const [updateUserLocationMutation] = useUpdateUserLocationMutation();
  const [createUserLocationMutation] = useCreateUserLocationMutation();

  useEffect(() => {
    if (breakpoints.md) setDrawerWidth('500px');
    else setDrawerWidth('100%');
  }, [breakpoints]);

  useEffect(() => {
    form.setFieldsValue({
      title: userLocation?.title,
      streetAddress: userLocation?.streetAddress,
      city: userLocation?.city,
      state: userLocation?.state,
      country: userLocation?.country,
      latitude: userLocation?.latitude,
      longitude: userLocation?.longitude,
      instructions: userLocation?.instructions,
    });
  }, [userLocation]);

  const onFinish = () => {
    form
      .validateFields()
      .then((fieldsValue) => {
        let variables = {};
        if (userLocation) {
          variables = {
            ...fieldsValue,
          };
          updateUserLocationMutation({
            variables: {
              ...variables,
              id: userLocation?.id,
            },
          })
            .then(() => {
              message.success('Location is updated');
            })

            .catch((error: any) => {
              console.log(error);
              message.error('Error');
            });
          onCancel();
          onReset();
        } else {
          variables = {
            ...fieldsValue,
          };
          createUserLocationMutation({
            variables: { ...variables, customerId: parseInt(customerId!) },
          })
            .then((res) => {
              newLocation(res.data?.createUserLocation!);
              message.success('Location is Created');
            })
            .catch((error: any) => {
              console.log(error);
              message.error('Error');
            });
          onCancel();
          onReset();
        }
      })
      .catch((info) => {
        message.error('Error');
      });
  };
  const onReset = () => {
    form.resetFields();
  };
  return (
    <Drawer
      visible={visible}
      title={userLocation ? 'Edit Location' : 'Add Location'}
      width={drawerWidth}
      onClose={onCancel}
      bodyStyle={{ paddingBottom: 80 }}
      extra={
        <Space>
          <Button
            onClick={() => {
              onCancel();
              onReset();
            }}
          >
            Cancel
          </Button>

          <Button type='primary' onClick={onFinish}>
            Save
          </Button>
        </Space>
      }
    >
      <Form form={form} layout='vertical' hideRequiredMark>
        <Form.Item
          name='title'
          label='Title'
          rules={[
            {
              required: true,
              message: 'Please Entre Title!',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name='streetAddress'
          label='Street Address'
          rules={[
            {
              required: true,
              message: 'Please Entre StreetAddress!',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            style={{ display: 'inline-block', width: '50%' }}
            name='city'
            label='City'
            rules={[
              {
                required: true,
                message: 'Please Entre City!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name='state'
            label='State'
            style={{
              display: 'inline-block',
              width: 'calc(50% - 8px)',
              marginLeft: '8px',
            }}
          >
            <Input />
          </Form.Item>
        </Form.Item>

        <Form.Item name='country' label='Country'>
          <Input />
        </Form.Item>

        <Form.Item name='instructions' label='Instructions'>
          <TextArea />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default UserLocationForm;
