import React from 'react';
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import Customers from './routes/Customers/Loadable';
import CustomerDetails from './routes/CustomerDetails';
import Brands from './routes/Brands/Loadable';
import Dashboard from './routes/Dashboard/Loadable';
import DailySales from './routes/DailySales/Loadable';
import Deals from './routes/Deals/Loadable';
import Discounts from './routes/Discounts/Loadable';
import DiscountDetails from './routes/DiscountDetails';
import EditDiscount from './routes/EditDiscount/Loadable';
import EditOrder from './routes/EditOrder/Loadable';
import Expenses from './routes/Expenses/Loadable';
import ExpensesByDate from './routes/ExpensesByDate/Loadable';
import ExpensesByItem from './routes/ExpensesByItem/Loadable';
import ExpenseItems from './routes/ExpenseItems/Loadable';
import KitchenDashboard from './routes/Kitchen/Dashboard';
import Login from './routes/Login/Loadable';
import NewDeal from './routes/NewDeal/Loadable';
import Menu from './routes//Menu/Loadable';
import Menus from './routes//Menus/Loadable';
import NewOrder from './routes/NewOrder/Loadable';
import NewDiscount from './routes/NewDiscount/Loadable';
import Orders from './routes/Orders/Loadable';
import OrdersByDate from './routes/OrdersByDate/Loadable';
import PurchaseRequest from './routes/PurchaseRequest/Loadable';
import Products from './routes/Products/Loadable';
import ProductCategories from './routes/ProductCategories';
import Reports from './routes/Reports/Loadable';
import Stocks from './routes/Stocks/Loadable';
import StockItems from './routes/StockItems/Loadable';
import SiteLayout from './components/Layout/Layout';
import Suppliers from './routes/Suppliers/Loadable';
import Chart from './routes/chart';
import { IUserSession } from './types';
import './App.scss';

interface ProtectedRouteProps {
  children?: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const location = useLocation();
  let userSession: IUserSession | null = null;

  try {
    const userSessionString = localStorage.getItem('userSession');
    userSession = userSessionString ? JSON.parse(userSessionString) : null;
  } catch (e) {
    console.log(e);
    userSession = null;
  }

  if (isEmpty(userSession) && location.pathname !== '/login') {
    return <Navigate to='/login' replace />;
  }

  return children ? children : <Outlet />;
};

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <SiteLayout>
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route path='/' element={<Dashboard />} />
            <Route path='/brands' element={<Brands />} />
            <Route path='/chart' element={<Chart />} />
            <Route path='/customers' element={<Customers />} />
            <Route
              path='/customers/:customerId'
              element={<CustomerDetails />}
            />
            <Route path='/daily-sales' element={<DailySales />} />
            <Route path='/deals' element={<Deals />} />
            <Route path='/deals/:dealId/edit' element={<NewDeal />} />
            <Route path='/discounts' element={<Discounts />} />
            <Route path='/discount/:discountId' element={<DiscountDetails />} />
            <Route path='/discounts/new' element={<NewDiscount />} />
            <Route
              path='/discounts/:discountId/edit'
              element={<EditDiscount />}
            />
            <Route path='/expenses' element={<Expenses />} />
            <Route path='/expense-items' element={<ExpenseItems />} />
            <Route path='/login' element={<Login />} />
            <Route path='/menus' element={<Menus />} />
            <Route path='/menu/:id' element={<Menu />} />
            <Route path='/new-deal' element={<NewDeal />} />
            <Route path='/orders/new' element={<NewOrder />} />
            <Route path='/orders' element={<Orders />} />
            <Route path='/orders/:orderId/edit' element={<EditOrder />} />
            <Route path='/purchase-request' element={<PurchaseRequest />} />
            <Route path='/reports' element={<Reports />} />
            <Route path='/products' element={<Products />} />
            <Route path='/product-categories' element={<ProductCategories />} />
            <Route path='/suppliers' element={<Suppliers />} />
            <Route path='/kitchen/dashboard' element={<KitchenDashboard />} />
            <Route
              path='/reports/expenses-by-date'
              element={<ExpensesByDate />}
            />
            <Route path='/reports/orders-by-date' element={<OrdersByDate />} />
            <Route path='/expenses-by-item' element={<ExpensesByItem />} />
            <Route path='/stocks' element={<Stocks />} />
            <Route path='/stocks/:stockId/items' element={<StockItems />} />
            <Route path='*' element={<p>This page does not exist.</p>} />
          </Route>
        </Routes>
      </SiteLayout>
    </BrowserRouter>
  );
};

export default App;
