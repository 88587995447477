import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import debounce from 'lodash.debounce';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import styles from './SearchInput.module.scss';

interface Props {
  filterItems: (searchItem: string) => void;
  placeholder: string;
}

const SearchInput: React.FC<Props> = ({ filterItems, placeholder }) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const inputRef = useRef<any>(null);

  useEffect(() => {
    filterItems(searchQuery);
  }, [searchQuery]);

  const onSearch = useCallback(
    (event: any) => {
      setSearchQuery(event.target.value);
    },
    [searchQuery]
  );

  const debouncedChangeHandler = useMemo(
    () => debounce(onSearch, 300),
    [onSearch]
  );

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
    };
  }, [debouncedChangeHandler]);

  const handelClick = () => {
    inputRef.current.value = '';
    setSearchQuery('');
  };

  return (
    <div className={styles.searchInput}>
      <SearchOutlined className={styles.searchIcon} />
      {searchQuery && (
        <CloseOutlined className={styles.clearIcon} onClick={handelClick} />
      )}
      <input
        ref={inputRef}
        className={styles.input}
        onChange={debouncedChangeHandler}
        type='text'
        placeholder={placeholder}
      />
    </div>
  );
};

export default SearchInput;
