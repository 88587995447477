import { useState } from 'react';
import { Table } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { ProductCategory } from '../../generated/graphql';
import styles from './ProductCategoriesList.module.scss';
import EditProductCategory from '../EditProductCategoryForm';
import PlaceholderImage from '../PlaceholderImage';
import TableActionsColumn from '../TableActionsColumn';
import { EditOutlined } from '@ant-design/icons';

interface supplierProps {
  productCategories: Partial<ProductCategory>[];
  updateProductCategory: (supplier: Partial<ProductCategory>) => void;
}

const ProductCategoriesList: React.FC<supplierProps> = ({
  productCategories,
  updateProductCategory,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [record, setRecord] = useState<Partial<ProductCategory>>();

  const onEdit = () => {
    setIsOpen(true);
  };
  const actions = [
    {
      key: 'edit',
      icon: <EditOutlined style={{ marginRight: 8 }} />,
      label: (
        <Link
          to='#'
          onClick={() => {
            onEdit();
          }}
        >
          Edit
        </Link>
      ),
    },
  ];

  const columns = [
    {
      title: 'Image',
      render: () => {
        return <PlaceholderImage width={50} height={50} />;
      },
      key: 'image',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Action',
      render: () => {
        return <TableActionsColumn actions={actions} />;
      },
      onCell: (data: Partial<ProductCategory>) => {
        return {
          onClick: () => {
            setRecord(data!);
          },
        };
      },
    },
  ];

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className={styles.productCategoriesList}>
        <Table
          columns={columns}
          dataSource={productCategories}
          scroll={{ x: true }}
        ></Table>
      </div>
      {isOpen && (
        <EditProductCategory
          productCategory={record!}
          isOpen={isOpen}
          onClose={onClose}
          updateProductCategory={updateProductCategory}
        />
      )}
    </>
  );
};

export default ProductCategoriesList;
