import React, { useEffect, useState } from 'react';
import { useDiscountLazyQuery } from '../../generated/graphql';
import { useParams } from 'react-router-dom';
import { DiscountType } from '../../types';
import styles from './DiscountDetails.module.scss';
import { Layout, Typography, Badge } from 'antd';
import { formatTime } from '../../utils/date';
import PageTitle from '../../components/PageHeading';
import { Link } from 'react-router-dom';
import Loading from '../../components/Loading';
import DataRow from '../../components/DataRow';
const { Content } = Layout;
const { Title, Text } = Typography;

const DiscountDetails = () => {
  const params = useParams();
  const [discountQuery, { data, error, loading }] = useDiscountLazyQuery();
  const [discount, setDiscount] = useState<DiscountType | null>();
  useEffect(() => {
    const discountId = params.discountId;
    if (discountId) {
      discountQuery({ variables: { id: discountId } });
    }
  }, [discountQuery, params.discountId]);

  useEffect(() => {
    setDiscount(data?.discount);
  }, [data]);
  return (
    <>
      {loading && <Loading loading={loading} />}
      {!loading && (
        <Layout className={styles.discountDetails}>
          <Content className={styles.wrapper}>
            <div className={styles.PageHeading}>
              <div className={styles.heading}>
                <PageTitle title={discount?.name!} />
                <span className={styles.status}>{discount?.status}</span>
              </div>
              <Link
                to={`/discounts/${discount?.uuid}/edit`}
                className='ant-btn ant-btn-primary'
              >
                Edit
              </Link>
            </div>
            <DataRow label='Code' value={discount?.code!} />
            <DataRow label='Description' value={discount?.description!} />

            <DataRow
              label='Discount'
              value={`${discount?.discountValue}${
                discount?.discountType === 'percentage' ? '%' : 'PKR'
              }`}
            />
            <DataRow label='Minimum Order' value={discount?.minOrderValue!} />
            <DataRow label='Start Date' value={discount?.startDate!} />
            <DataRow label='Expire Date' value={discount?.expiryDate!} />
            <DataRow
              label='Opening Time'
              value={formatTime(discount?.openingTime)!}
            />
            <DataRow
              label='Closing Time'
              value={formatTime(discount?.closingTime)!}
            />
          </Content>
        </Layout>
      )}
    </>
  );
};

export default DiscountDetails;
