import { Empty } from 'antd';

const EmptyPlaceholder: React.FC = () => {
  return (
    <div
      style={{
        minHeight: 200,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Empty description='No entries found' />
    </div>
  );
};

export default EmptyPlaceholder;
