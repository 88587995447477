import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Dropdown } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

interface Props {
  actions?: any;
}

const TableActionsColumn: React.FC<Props> = ({ actions }) => {
  return (
    <div>
      <Dropdown
        overlay={<Menu items={actions} />}
        trigger={['click']}
        placement='bottomRight'
      >
        <Link to='#'
          onClick={(e) => e.preventDefault()}
          style={{ color: '#000', width: 40 }}
        >
          <MoreOutlined />
        </Link>
      </Dropdown>
    </div>
  );
};

export default TableActionsColumn;
