export const queries = {
  xs: '(min-width: 480px)',
  sm: '(min-width: 576px)',
  md: '(min-width: 768px)',
  lg: '(min-width: 992px)',
  xl: '(min-width: 1200px)',
  xxl: '(min-width: 1600px)',
  or: '(orientation: portrait)',
};

export const UNITS = [
  'kilogram',
  'gram',
  'milligram',
  'kilopound',
  'pound',
  'millipound',
  'kiloounce',
  'ounce',
  'milliounce',
  'kiloliter',
  'liter',
  'milliliter',
  'kilogallon',
  'gallon',
  'milligallon',
  'dozen',
  'day',
  'week',
  '1',
  'packet',
  'item',
];

export const stockItemsStatus = [
  {
    text: 'Active',
    value: 'active',
  },
  {
    text: 'Fulfilled',
    value: 'fulfilled',
  },
  {
    text: 'InActive',
    value: 'inactive',
  },
];
