import * as React from 'react';
import { Layout, Typography } from 'antd';
import { Link } from 'react-router-dom';
import styles from './Dashboard.module.scss';

const { Content } = Layout;
const { Title } = Typography;

const KitchenDashboard: React.FC<{}> = () => (
  <Layout className={styles.kitchenDashboard}>
    <Content className={styles.wrapper}>
      <Title level={3}>Kitchen Dashboard</Title>
    </Content>
  </Layout>
);

export default KitchenDashboard;
