import React, { FC, useState, useEffect } from 'react';
import {
  AppstoreOutlined,
  BarChartOutlined,
  ContainerOutlined,
  FireOutlined,
  HomeOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ShoppingOutlined,
  UserOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { Button, Layout, Menu } from 'antd';
import styles from './Layout.module.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useBreakpoint } from './../../hooks/breakpoints';

const { Header, Sider, Content } = Layout;
// const wideLayoutRoutes = ['/orders/new', '/new-deal'];
const wideLayoutRoutes: string[] = ['/purchase-request', '/login'];
interface LayoutProps {
  children: React.ReactNode;
}

const items = [
  {
    key: 'dashboard',
    icon: <HomeOutlined />,
    label: <Link to='/'>Dashboard</Link>,
  },
  {
    key: 'menus',
    icon: <ContainerOutlined />,
    label: <Link to='/menus'>Menus</Link>,
  },
  {
    key: 'sales',
    icon: <ShoppingOutlined />,
    label: 'Sales',
    children: [
      {
        key: 'expenses',
        label: <Link to='/expenses'>Expenses</Link>,
      },
      {
        key: 'expense-items',
        label: <Link to='/expense-items'>Expense Items</Link>,
      },
      {
        key: 'orders',
        label: <Link to='/orders'>Orders</Link>,
      },
      {
        key: 'daily-sales',
        label: <Link to='/daily-sales'>Daily Sales</Link>,
      },
    ],
  },
  {
    key: 'marketing',
    icon: <ShoppingOutlined />,
    label: 'Marketing',
    children: [
      {
        key: 'deals',
        label: <Link to='/deals'>Deals</Link>,
      },
      {
        key: 'discounts',
        label: <Link to='/discounts'>Discounts</Link>,
      }
    ],
  },
  {
    key: 'reports',
    icon: <BarChartOutlined />,
    label: 'Reports',
    children: [
      {
        key: 'orders-by-date',
        label: <Link to='/reports/orders-by-date'>Orders By Date</Link>,
      },
      {
        key: 'expenses-by-date',
        label: <Link to='/reports/expenses-by-date'>Expense By Date</Link>,
      },
      {
        key: 'expenses-by-item',
        label: <Link to='/expenses-by-item'>Expense By Item</Link>,
      },
    ],
  },
  {
    key: 'kitchen',
    icon: <FireOutlined />,
    label: 'Kitchen',
    children: [
      {
        key: 'kitchen-dashboard',
        label: <Link to='/kitchen/dashboard'>Dashboard</Link>,
      },
    ],
  },
  {
    key: 'inventory',
    icon: <AppstoreOutlined />,
    label: 'Inventory',
    children: [
      {
        key: 'inventory-dashboard',
        label: <Link to='/inventory/dashboard'>Dashboard</Link>,
      },
      {
        key: 'inventory-products',
        label: <Link to='/products'>Products</Link>,
      },
      {
        key: 'inventory-product-categories',
        label: (
          <Link to='/product-categories'>Product Categories</Link>
        ),
      },
      {
        key: 'inventory-stock',
        label: <Link to='/stocks'>Stocks</Link>,
      },
      {
        key: 'inventory-brands',
        label: <Link to='/brands'>Brands</Link>,
      },
      {
        key: 'inventory-suppliers',
        label: <Link to='/suppliers'>Suppliers</Link>,
      },
      {
        key: 'purchase-request',
        label: <Link to='/purchase-request'>Purchase Requests</Link>,
      },
    ],
  },
  {
    key: 'human-resource',
    icon: <UserOutlined />,
    label: 'Human Resource',
    children: [
      {
        key: 'hr-staff',
        label: <Link to='/hr/staff'>Staff</Link>,
      },
      {
        key: 'hr-leaves',
        label: <Link to='/hr/leave-management'>Leave Management</Link>,
      },
      {
        key: 'hr-payroll',
        label: <Link to='/hr/leave-management'>Payroll</Link>,
      },
      {
        key: 'hr-attendance',
        label: <Link to='/hr/attendance'>Attendance</Link>,
      },
    ],
  },
  {
    key: 'customers',
    icon: <UserOutlined />,
    label: 'Customers',
    children: [
      {
        key: 'customers-list',
        label: <Link to='/customers'>Customers</Link>,
      },
    ],
  },
  {
    key: 'accounts',
    icon: <WalletOutlined />,
    label: 'Accounts',
    children: [
      {
        key: 'accounts-list',
        label: <Link to='/accounts'>Accounts</Link>,
      },
    ],
  },
];

const SiteLayout: FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showSider, setShowSider] = useState(false);
  const breakpoints: any = useBreakpoint();
  const [isWideLayout, setIsWideLayout] = useState<boolean>(false);

  useEffect(() => setShowSider(breakpoints.lg), [breakpoints]);
  useEffect(
    () => setIsWideLayout(wideLayoutRoutes.includes(location.pathname)),
    [location.pathname]
  );

  const handleLogout = () => {
    localStorage.setItem('userSession', '');
    navigate('/login', { replace: true });
  };

  return (
    <>
      {!isWideLayout && (
        <Layout className={styles.HeaderLayout}>
          {showSider && (
            <Sider
              trigger={null}
              collapsible
              collapsed={false}
              theme='light'
              className={styles.HeaderLayout__sider}
            >
              <div className={styles.HeaderLayout__logo}>
                <span>Food Gorilla</span>
              </div>
              <Menu
                theme='light'
                mode='inline'
                defaultSelectedKeys={['1']}
                items={items}
              />
              <Button type='link' onClick={handleLogout}>
                Logout
              </Button>
            </Sider>
          )}
          <Layout className={styles.SiteLayout}>
            <Header
              className={`${styles.SiteLayout__background} ${styles.SiteLayout__header}`}
              style={{ padding: 0 }}
            >
              {React.createElement(
                showSider ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  style: {
                    padding: '0 24px',
                    fontSize: '18px',
                    lineHeight: '64px',
                    cursor: 'pointer',
                    transition: 'color 0.3s',
                  },
                  onClick: () => {
                    setShowSider(!showSider);
                  },
                }
              )}
            </Header>
            <Content className={styles.SiteLayout__background}>
              {children}
            </Content>
          </Layout>
        </Layout>
      )}
      {isWideLayout && (
        <Content className={styles.SiteLayout__background}>{children}</Content>
      )}
    </>
  );
};

export default SiteLayout;
