import moment, { Moment } from 'moment';

type DateTimeType = string | Moment | null | undefined;

export const formatDateTime = (date: DateTimeType) => {
  if (date) {
    return moment(date).format('DD/MM/YYYY, hh:mm A');
  }
};

export const formatOnlyDate = (date: DateTimeType) => {
  if (date) {
    return moment(date).format('DD/MM/YYYY');
  }
};

export const formatTime = (date: DateTimeType) => {
  if (date) {
    return moment(date).format('hh:mm A');
  }
};

export const combineDateAndTime = (date: DateTimeType, time: DateTimeType) => {
  if (!moment.isMoment(date)) {
    date = moment(date);
  }

  if (!moment.isMoment(time)) {
    time = moment(time);
  }

  return moment(
    `${date.format('DD/MM/YYYY')} ${time.format('hh:mm:ss A')}`,
    'DD/MM/YYYY HH:mm:ss A'
  );
};
