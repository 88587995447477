import { Form, Input, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';

interface Props {
  visible: boolean;
  onCreate: (values: string) => void;
  onCancel: () => void;
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const NewProductCategoryForm: React.FC<Props> = ({
  visible,
  onCreate,
  onCancel,
}) => {
  const [form] = Form.useForm();

  return (
    <Modal
      visible={visible}
      title='New Product Category'
      okText='Create'
      cancelText='Cancel'
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((fieldsValue: any) => {
            form.resetFields();
            const values = {
              ...fieldsValue,
            };
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validation failed:', info);
          });
      }}
    >
      <Form form={form} {...layout} name='new_ProductCategory_form'>
        <Form.Item name='name' label='Name' rules={[{ required: true }]}>
          <Input />
        </Form.Item>

        <Form.Item name='description' label='Description'>
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default NewProductCategoryForm;
