import React, { useState, useEffect } from 'react';
import { Button, Drawer, Form, Input, Space } from 'antd';
import { useBreakpoint } from './../../hooks/breakpoints';
import {
  ProductCategory,
  useUpdateProductCategoryMutation,
} from '../../generated/graphql';
import TextArea from 'antd/lib/input/TextArea';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  productCategory: Partial<ProductCategory>;
  updateProductCategory: (productCategory: Partial<ProductCategory>) => void;
}

const EditProductCategory: React.FC<Props> = ({
  isOpen,
  onClose,
  productCategory,
  updateProductCategory,
}) => {
  const [drawerWidth, setDrawerWidth] = useState<string>('500px');
  const breakpoints: any = useBreakpoint();
  const [form] = Form.useForm();
  const [updateProductCategoryMutation, { data, error, loading }] =
    useUpdateProductCategoryMutation();

  useEffect(() => {
    if (breakpoints.md) setDrawerWidth('500px');
    else setDrawerWidth('100%');
  }, [breakpoints]);

  const onFinish = () => {
    form
      .validateFields()
      .then((fieldsValue) => {
        const variables = {
          id: productCategory.id,
          ...fieldsValue,
        };
        updateProductCategoryMutation({ variables }).then((response) => {
          console.log(response.data?.updateProductCategory);
        });
        onClose();
      })
      .catch((error) => {
        console.log('Validation Failed:', error);
      });
  };

  return (
    <Drawer
      visible={isOpen}
      title='Edit Product Category'
      width={drawerWidth}
      onClose={onClose}
      bodyStyle={{ paddingBottom: 80 }}
      extra={
        <Space>
          <Button onClick={onClose}>Cancel</Button>
          <Button type='primary' onClick={onFinish}>
            Save
          </Button>
        </Space>
      }
    >
      <Form
        form={form}
        layout='vertical'
        hideRequiredMark
        initialValues={{
          name: productCategory.name,
          description: productCategory.description,
        }}
        name='Edit-Product-Category-form'
      >
        <Form.Item name='name' label='Name'>
          <Input />
        </Form.Item>
        <Form.Item name='description' label='Description'>
          <TextArea />
        </Form.Item>
      </Form>
    </Drawer>
  );
};

export default EditProductCategory;
