import React, { useState, useEffect } from 'react';
import { CustomerType, UserLocationType } from '../../types';
import {
  useCustomerLazyQuery,
  useDeleteUserLocationMutation,
} from '../../generated/graphql';
import { useParams } from 'react-router-dom';
import Error from '../../components/Error';
import Loading from '../../components/Loading';
import EmptyPlaceholder from '../../components/EmptyPlaceholder';
import isEmpty from 'lodash.isempty';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import PageTitle from '../../components/PageHeading';
import { Button, Layout, Typography, message, Tooltip } from 'antd';
import { Content } from 'antd/lib/layout/layout';
import styles from './CustomerDetails.module.scss';
import UserLocationForm from '../../components/UserLocationForm';
import DataRow from '../../components/DataRow';
const { Title, Text } = Typography;

const CustomerDetails = () => {
  const params = useParams();
  const [visible, setVisible] = useState(false);
  const [edit, setEdit] = useState(false);
  const [userLocation, setUserLocation] = useState<UserLocationType>();
  const [customerQuery, { data, error, loading }] = useCustomerLazyQuery();
  const [customer, setCustomer] = useState<CustomerType | null>();
  const [locations, setLocations] = useState<
    UserLocationType[] | null | undefined
  >();
  let [deleteUserLocationMutation] = useDeleteUserLocationMutation();

  const customerId = params.customerId;

  useEffect(() => {
    if (customerId) {
      customerQuery({ variables: { id: customerId } });
    }
  }, [customerQuery, params.customerId]);

  useEffect(() => {
    setCustomer(data?.customer);
  }, [data]);

  useEffect(() => {
    setLocations(customer?.userLocations);
  }, [customer]);

  const onDeleteLocation = (userLocationId: string) => {
    deleteUserLocationMutation({ variables: { id: userLocationId } }).then(
      () => {
        if (customer?.userLocations) {
          const updatedUserLocations: UserLocationType[] = [
            ...customer?.userLocations,
          ];
          const index = updatedUserLocations.findIndex(
            (d) => d.id === userLocationId
          );
          updatedUserLocations.splice(index, 1);
          setLocations(updatedUserLocations);
          message.success('Location is deleted successfully');
        }
      }
    );
  };

  const newLocation = (location: UserLocationType | undefined | null) => {
    setLocations(locations?.concat(location!));
  };

  return (
    <>
      {error && <Error />}
      <Loading loading={loading} />
      {!loading && !error && (
        <>
          {!isEmpty(customer) && (
            <Layout className={styles.customerDetails}>
              <Content className={styles.wrapper}>
                <div className={styles.PageHeading}>
                  <PageTitle
                    title={`${customer?.user.firstName} ${
                      customer?.user.lastName !== null
                        ? customer?.user.lastName
                        : ''
                    }`}
                  />
                </div>
                <div style={{ marginBottom: '24px' }}>
                  <DataRow
                    label='Customer Number'
                    value={customer?.customerNumber!}
                  />
                  <DataRow
                    label='Phone Number'
                    value={customer?.user.mobileNumber!}
                  />
                  <DataRow label='Email' value={customer?.user.email!} />
                  <DataRow
                    label='Salutation'
                    value={customer?.user.salutation!}
                  />
                  <DataRow label='Status' value={customer?.status!} />
                  <DataRow
                    label='Billing Status'
                    value={customer?.billingStatus!}
                  />
                  <DataRow label='Vendor' value={customer?.vendor.name!} />
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '16px',
                    marginBottom: '16px',
                  }}
                >
                  <Title level={4} style={{ marginBottom: 0 }}>
                    Locations
                  </Title>
                  <Button
                    className={styles.btn}
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setVisible(true);
                    }}
                  />
                </div>
                <div>
                  {!isEmpty(locations) &&
                    locations?.map((location) => {
                      return (
                        <div className={styles.details}>
                          <Title
                            level={5}
                            className={styles.location}
                          >{`${location.title} location`}</Title>
                          <div className={styles.address}>
                            <Text
                              className={styles.location}
                            >{`${location.streetAddress}, ${location.city}, ${location.state}`}</Text>
                            <div>
                              <EditOutlined
                                className={styles.editIcon}
                                style={{ marginRight: '16px' }}
                                onClick={() => {
                                  setUserLocation(location);
                                  setEdit(true);
                                  setVisible(true);
                                }}
                              />
                              <DeleteOutlined
                                className={styles.deleteIcon}
                                onClick={() => onDeleteLocation(location.id)}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </Content>
            </Layout>
          )}
          {isEmpty(customer) && <EmptyPlaceholder />}

          <UserLocationForm
            userLocation={edit && visible ? userLocation : null}
            customerId={customerId}
            newLocation={newLocation}
            visible={visible}
            onCancel={() => {
              setVisible(false);
              setEdit(false);
            }}
          />
        </>
      )}
    </>
  );
};

export default CustomerDetails;
